import { Card } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { terrainBrown } from "../../App";

const useStyles = makeStyles((theme) => ({
  outer: {
    flex: 1,
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  card: {
    background: "transparent",
  },
  cardInner: {
    padding: theme.spacing(2),
    paddingTop: theme.spacing(4),
    borderTop: "12px solid " + theme.palette.primary.main,
    borderRadius: 5,
    background: "white",
    borderBottom: "12px solid " + terrainBrown,
  },
  img: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

export function TerrainModalFormat(props: {
  onSubmit(): void;
  inputs: JSX.Element;
  actions: JSX.Element;
}) {
  const styles = useStyles();
  return (
    <div className={styles.outer}>
      <Card elevation={2} className={styles.card}>
        <div className={styles.cardInner}>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              props.onSubmit();
            }}
          >
            <Grid container direction="column" alignItems="center" spacing={1}>
              <Grid item>
                <img
                  className={styles.img}
                  alt="logo"
                  src="/mainapp/login-logo.png"
                  height={40}
                />
              </Grid>
              {props.inputs}
            </Grid>
            <div style={{ height: 30 }} />
            {props.actions}
          </form>
        </div>
      </Card>
    </div>
  );
}
