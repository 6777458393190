import { SubAPI } from "./SubAPI";
import { DateString } from "./API";

export class OverheadAPI extends SubAPI {
  listEmployeeRates() {
    return this.fetchPaginated<OverheadRate>(`/api/employee-overhead/list`, {});
  }

  updateEmployeeRate(props: OverheadRate) {
    return this.fetcher.post<OverheadRate>(
      `/api/employee-overhead/update`,
      props
    );
  }

  createEmployeeRate(props: any) {
    return this.fetcher.post<OverheadRate>(
      `/api/employee-overhead/create`,
      props
    );
  }

  removeEmployeeRates(props: number[]) {
    return this.fetcher.post<{}>(`/api/employee-overhead/remove`, props);
  }

  listContractorRates() {
    return this.fetchPaginated<OverheadRate>(
      `/api/contractor-overhead/list`,
      {}
    );
  }

  updateContractorRate(props: OverheadRate) {
    return this.fetcher.post<OverheadRate>(
      `/api/contractor-overhead/update`,
      props
    );
  }

  createContractorRate(props: any) {
    return this.fetcher.post<OverheadRate>(
      `/api/contractor-overhead/create`,
      props
    );
  }

  removeContractorRates(props: number[]) {
    return this.fetcher.post<{}>(`/api/contractor-overhead/remove`, props);
  }
}

export interface OverheadRate {
  id: number;
  rateCents: number;
  division: string;
  startDate: DateString;
  archived: boolean;
}
