import { SubAPI } from "./SubAPI";
import { Cents } from "nate-react-api-helpers";
import { OrderByDirection } from "../pages/table/TableViewData";
import { DateString } from "./API";

export class CostSummary extends SubAPI {
  summary(props: {
    project: number;
    orderBy: string;
    orderByDirection: OrderByDirection;
    search: string;
  }) {
    return this.fetcher.get<Summary[]>("/api/costing/summary/list", props);
  }

  detail(props: {
    project: number;
    category: string;
    offset: number;
    limit: number;
  }) {
    return this.fetcher.get<Detail[]>("/api/costing/summary/details", props);
  }
}

export interface Summary {
  id: number;
  category: string;
  qty: number;
  costCents: Cents;
}

export interface Detail {
  id: number;
  date: DateString;
  description: string;
  qty: number;
  costCents: Cents;
}
