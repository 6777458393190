import { useAuthenticated } from "nate-react-api-helpers";

export function useCurrentUser() {
  const auth = useAuthenticated();
  return auth.lastPingResult as null | {
    firstName: string;
    lastName: string;
    id: number;
    role: string;
    workEmail: string;
  };
}

export function useRole() {
  const u = useCurrentUser();
  return u?.role;
}

export function useIsOfficeManager() {
  return useRole() === "office_manager";
}

export function useIsProjectManager() {
  return useRole() === "project_manager";
}

export function useIsMechanic() {
  return useRole() === "mechanic";
}

export function useIsOfficeAdmin() {
  return useRole() === "office_admin";
}
