import { useCurrentUser } from "./Auth";
import { Redirect } from "react-router-dom";
import React from "react";

export function Redirector() {
  const auth = useCurrentUser();

  switch (auth?.role) {
    case "mechanic":
      return <Redirect to="/service-records" />;
    case undefined:
      return null;
    default:
      return <Redirect to="/projects" />;
  }
}
