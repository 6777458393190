import { SubAPI } from "./SubAPI";
import { OrderByDirection } from "../pages/table/TableViewData";
import { DateString } from "./API";
import { Cents } from "nate-react-api-helpers";

export class ProjectInvoiceAPI extends SubAPI {
  list(props: {
    parentId?: number;
    page: number;
    download?: string;
    search: string;
    pageSize: number;
    orderBy: string;
    filter: string;
    orderByDirection: OrderByDirection;
  }) {
    return this.fetchPaginated<ProjectInvoice>(
      `/api/project-invoice/list`,
      props
    );
  }

  remove(props: number[]) {
    return this.fetcher.post<{}>(`/api/project-invoice/remove`, props);
  }

  insert(props: any) {
    return this.fetcher.post<ProjectInvoice>(
      `/api/project-invoice/create`,
      props
    );
  }

  update(props: ProjectInvoice) {
    return this.fetcher.post<ProjectInvoice>(
      `/api/project-invoice/update`,
      props
    );
  }
}

export interface ProjectInvoice {
  id: number;
  project: number;
  progressNo: string;
  invoiceNo: string;
  date: DateString;
  amountCentsWithoutTax: Cents;
  maintHbCents: Cents;
  statHbCents: Cents;
  archived: boolean;
}
