import { api } from "../../api/API";
import React, { useState } from "react";
import { Col } from "../table/TableViewData";
import { Project } from "../../api/Projects";
import capitalize from "@material-ui/core/utils/capitalize";
import { Chip } from "@material-ui/core";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import { TLinkButton } from "../TLinkButton";
import { TableViewDataAsync } from "../table/TableViewDataAsync";
import Grid from "@material-ui/core/Grid";
import { TableViewSearch } from "../table/TableViewSearch";

const cols: Col<Project>[] = [
  {
    name: "#",
    selector: (e) => e.projectCode,
  },
  {
    name: "Site",
    selector: (e) => e.siteName,
  },
  {
    name: "Customer",
    selector: (e) => e.customer,
  },
  {
    name: "Division",
    selector: (e) => capitalize(e.division),
  },
  {
    name: "Project Manager",
    actions: true,
    selector: (e) => {
      const chips = e.projectManagers.map((p) => (
        <Chip
          size="small"
          style={{ marginRight: 5 }}
          key={p.id}
          label={p.firstName + " " + p.lastName}
          icon={<AccountCircleIcon />}
        />
      ));
      return <>{chips || <i>None</i>}</>;
    },
  },
  {
    name: "Actions",
    actions: true,
    selector: (e) => <TLinkButton title="View" to={"/project/" + e.id} />,
  },
];

export function ProjectList(props: {
  type: "archived" | "pending" | "active";
}) {
  const [search, setSearch] = useState("");
  const [selection, setSelection] = useState<number[]>([]);
  console.debug(selection);

  return (
    <>
      <Grid container justify="space-between" alignItems="center">
        <TableViewSearch placeholder="Search Projects" onChange={setSearch} />
        <Grid item></Grid>
      </Grid>
      <TableViewDataAsync
        cols={cols}
        tab={props.type}
        search={search}
        onSelectionChange={setSelection}
        navigateTo={(row) => "/project/" + row.id}
        fetcher={(opts) =>
          api.projects.list({
            pageSize: opts.pageSize,
            page: opts.page,
            search: opts.search,
            type: opts.tab as any,
            orderBy: opts.orderBy,
            orderByDirection: opts.orderByDirection,
          })
        }
      />
    </>
  );
}
