import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Col, OrderByDirection, TableViewData } from "./TableViewData";
import { Paginated } from "../../api/API";
import { useAsync2 } from "nate-react-api-helpers";
import { blankArray, pageSize } from "./TableViewSimple";
import { TableContext } from "./TableProvider";
import { downloadResultAsFile } from "./TableViewDataSimple";

export function TableViewDataAsync<T extends { id: number }>(props: {
  cols: Col<T>[];
  tab?: string;
  search?: string;
  fetcher: (opts: {
    pageSize: number;
    page: number;
    tab: string;
    search: string;
    orderBy: string;
    orderByDirection: OrderByDirection;
  }) => Promise<Paginated<T>>;
  navigateTo?(row: T): string;
  onSelectionChange(values: number[]): void;
}) {
  const tab = props.tab;
  const search = props.search;

  const [page, setPage] = useState(0);
  const [orderBy, setOrderBy] = useState<string>(props.cols[0].name);
  const [orderByDirection, setOrderByDirection] = useState<OrderByDirection>(
    "desc"
  );

  const orderByChange = useCallback(
    (col: string, direction: OrderByDirection) => {
      setOrderBy(col);
      setOrderByDirection(direction);
    },
    []
  );

  const list = useAsync2(
    (opts) => props.fetcher(opts),
    {
      page: page,
      tab: tab || "",
      search: search || "",
      orderBy: orderBy,
      orderByDirection: orderByDirection,
      pageSize,
    },
    [page, search, orderBy, tab, orderByDirection]
  );

  const reload = list.reload;
  const { registerTable } = useContext(TableContext);
  const data = list.result?.data || [];

  useEffect(() => {
    registerTable({
      download: async (fileName: string) => {
        downloadResultAsFile(fileName, data, props.cols, false);
      },
      reload: reload,
    });
  }, [registerTable, reload, data, props.cols]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const navigateTo = useMemo(() => props.navigateTo, []);

  return (
    <TableViewData
      totalCount={list.result?.totalCount || 0}
      columns={props.cols}
      navigateTo={navigateTo}
      rows={list.result?.data || (blankArray as T[])}
      loading={list.loading}
      error={list.error}
      onOrderByChange={orderByChange}
      orderBy={orderBy}
      orderByDirection={orderByDirection}
      onSelectionChanged={props.onSelectionChange}
      onPageChange={setPage}
      rowsPerPage={pageSize}
      page={page}
    />
  );
}
