import { API, Paginated } from "./API";
import { ParsedUrlQueryInput } from "querystring";
import querystring from "querystring";

export class SubAPI {
  api: API;

  constructor(api: API) {
    this.api = api;
  }

  fetchPaginated<T>(
    path: string,
    props: ParsedUrlQueryInput & { download?: string }
  ): Promise<Paginated<T>> {
    if (props.download !== undefined) {
      window.location.href = path + "?" + querystring.stringify(props);
      return Promise.resolve({ data: [], totalCount: 0 });
    }

    return this.fetcher.get(path, props);
  }

  get fetcher() {
    return this.api.fetcher;
  }
}
