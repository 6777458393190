import React, { Suspense } from "react";
import "./App.css";
import {
  CircularProgress,
  CssBaseline,
  ThemeProvider,
} from "@material-ui/core";
import createMuiTheme from "@material-ui/core/styles/createMuiTheme";
import { AuthProvider } from "nate-react-api-helpers/lib/Auth";
import { api } from "./api/API";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import { LoginPage } from "./pages/login/LoginPage";
import { RequireLogin } from "./pages/login/RequireLogin";
import { TDatePickerProvider } from "./pages/TDatePicker";
import { TSnackbarProvider } from "./pages/TSnackbar";

import { TConfirmProvider } from "./pages/TConfirm";
import { TableProvider } from "./pages/table/TableProvider";
import { ProjectProvider } from "./pages/project/UseProject";
import { ProjectsPages } from "./pages/project/ProjectsPages";
import { Container } from "./pages/Container";
import { Redirector } from "./misc/Redirector";

const CreateTimeSheetPage = React.lazy(
  () => import("./pages/timeSheets/timeSheet/CreateTimeSheetPage")
);
const PayrollPage = React.lazy(() => import("./pages/payroll/PayrollPage"));
const EmployeeListPage = React.lazy(
  () => import("./pages/employees/EmployeeListPage")
);
const ForgotPassword = React.lazy(() => import("./pages/login/ForgotPassword"));
const ResetPassword = React.lazy(() => import("./pages/login/ResetPassword"));
const EmployeeDetailPage = React.lazy(
  () => import("./pages/employees/EmployeeDetailPage")
);
const CreateEmployeePage = React.lazy(
  () => import("./pages/employees/CreateEmployeePage")
);
const CreateProjectPage = React.lazy(
  () => import("./pages/project/CreateProjectPage")
);
const ProjectDetailPage = React.lazy(
  () => import("./pages/project/ProjectDetailPage")
);
const VehicleDetailPage = React.lazy(
  () => import("./pages/vehicles/VehicleDetailPage")
);
const VehicleListPage = React.lazy(
  () => import("./pages/vehicles/VehicleListPage")
);
const CreateVehiclePage = React.lazy(
  () => import("./pages/vehicles/CreateVehiclePage")
);
const CreateEquipmentPage = React.lazy(
  () => import("./pages/equipment/CreateEquipmentPage")
);
const EquipmentDetailPage = React.lazy(
  () => import("./pages/equipment/EquipmentDetailPage")
);
const EquipmentListPage = React.lazy(
  () => import("./pages/equipment/EquipmentListPage")
);
const TimeSheetPage = React.lazy(
  () => import("./pages/timeSheets/TimeSheetPage")
);
const DailyReport = React.lazy(
  () => import("./pages/project/dailyReport/DailyReport")
);
const TimeSheetSiteDetailPage = React.lazy(
  () => import("./pages/timeSheets/timeSheet/TimeSheetSiteDetailPage")
);
const TimeSheetDriverDetailPage = React.lazy(
  () => import("./pages/timeSheets/timeSheet/TimeSheetDriverDetailPage")
);
const TimeSheetDriverTaskDetailPage = React.lazy(
  () => import("./pages/timeSheets/timeSheet/TimeSheetDriverTaskDetailPage")
);
const InboxPage = React.lazy(() => import("./pages/inbox/InboxPage"));
const MobileBlastPage = React.lazy(
  () => import("./pages/mobileblast/MobileBlastPage")
);
const Documents = React.lazy(() => import("./pages/documents/Documents"));
const ProjectCostingPage = React.lazy(
  () => import("./pages/project/costing/ProjectCostingPage")
);
const ServiceRecordListPage = React.lazy(
  () => import("./pages/serviceRecords/ServiceRecordListPage")
);
const CreateServiceRecordPage = React.lazy(
  () => import("./pages/serviceRecords/CreateServiceRecordPage")
);
const ServiceRecordDetailPage = React.lazy(
  () => import("./pages/serviceRecords/ServiceRecordDetailPage")
);
const ReportsPage = React.lazy(() => import("./pages/reports/ReportsPage"));
const ServiceRequestDetailPage = React.lazy(
  () => import("./pages/serviceRequests/ServiceRequestDetailPage")
);
const ServiceRequestListPage = React.lazy(
  () => import("./pages/serviceRequests/ServiceRequestListPage")
);
const CreateServiceRequestPage = React.lazy(
  () => import("./pages/serviceRequests/CreateServiceRequestPage")
);

export const terrainGreen = "#789C48";
export const terrainBrown = "#3F3127";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: terrainGreen,
    },
    secondary: {
      main: "#906444",
    },
  },
  overrides: {
    MuiCard: {
      root: {
        flexShrink: 0,
      },
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <TConfirmProvider>
        <TDatePickerProvider>
          <TSnackbarProvider>
            <AuthProvider onPing={() => api.ping()} pingInterval={30 * 1000}>
              <TableProvider>
                <CssBaseline />

                <Router>
                  <Suspense
                    fallback={
                      <Container maxWidth={1000}>
                        <CircularProgress />
                      </Container>
                    }
                  >
                    <Switch>
                      <Route path="/login">
                        <LoginPage />
                      </Route>
                      <Route path="/forgot-password">
                        <ForgotPassword />
                      </Route>
                      <Route path="/reset-password/:code">
                        <ResetPassword />
                      </Route>
                      <Route path="/documents">
                        <Documents />
                      </Route>
                      <Route path="/employees/create">
                        <CreateEmployeePage />
                      </Route>
                      <Route path="/employees">
                        <EmployeeListPage />
                      </Route>
                      <Route path="/payroll">
                        <PayrollPage />
                      </Route>
                      <Route path="/communications">
                        <MobileBlastPage />
                      </Route>
                      <Route path="/time-sheets">
                        <TimeSheetPage />
                      </Route>
                      <Route path="/time-sheet/create">
                        <CreateTimeSheetPage />
                      </Route>
                      <Route path="/time-sheet/:timeSheetId/snow">
                        <TimeSheetSiteDetailPage />
                      </Route>
                      <Route
                        path="/time-sheet/:timeSheetId/driver-report"
                        exact
                      >
                        <TimeSheetDriverDetailPage />
                      </Route>
                      <Route path="/time-sheet/:timeSheetId/driver-report/:driverTaskId">
                        <TimeSheetDriverTaskDetailPage />
                      </Route>
                      <Route path="/employee/:id">
                        <EmployeeDetailPage />
                      </Route>
                      <Route path="/vehicles/create">
                        <CreateVehiclePage />
                      </Route>
                      <Route path="/vehicles">
                        <VehicleListPage />
                      </Route>
                      <Route path="/vehicle/:id">
                        <VehicleDetailPage />
                      </Route>
                      <Route path="/service-records/create">
                        <CreateServiceRecordPage />
                      </Route>
                      <Route path="/service-records">
                        <ServiceRecordListPage />
                      </Route>
                      <Route path="/service-record/:id">
                        <ServiceRecordDetailPage />
                      </Route>
                      <Route path="/service-request/create">
                        <CreateServiceRequestPage />
                      </Route>
                      <Route path="/service-requests">
                        <ServiceRequestListPage />
                      </Route>
                      <Route path="/service-request/:id">
                        <ServiceRequestDetailPage />
                      </Route>
                      <Route path="/equipments/create">
                        <CreateEquipmentPage />
                      </Route>
                      <Route path="/equipments">
                        <EquipmentListPage />
                      </Route>
                      <Route path="/equipment/:id">
                        <EquipmentDetailPage />
                      </Route>
                      <Route path="/project/create">
                        <CreateProjectPage />
                      </Route>
                      <Route path="/project/:id/costing">
                        <ProjectProvider>
                          <ProjectCostingPage />
                        </ProjectProvider>
                      </Route>
                      <Route path="/project/:id/daily-reports/:timeSheetId/:type/:siteId">
                        <ProjectProvider>
                          <DailyReport />
                        </ProjectProvider>
                      </Route>
                      <Route path="/project/:id">
                        <ProjectProvider>
                          <ProjectDetailPage />
                        </ProjectProvider>
                      </Route>
                      <Route path="/projects">
                        <ProjectsPages />
                      </Route>
                      <Route path="/reports/:id">
                        <ReportsPage />
                      </Route>
                      <Route path="/reports">
                        <ReportsPage />
                      </Route>
                      <Route path="/inbox" exact>
                        <InboxPage />
                      </Route>
                      <Route path="/" exact>
                        <Redirector />
                      </Route>
                      <Route path="*">
                        <Redirect to="/" />
                      </Route>
                    </Switch>
                    <RequireLogin />
                  </Suspense>
                </Router>
              </TableProvider>
            </AuthProvider>
          </TSnackbarProvider>
        </TDatePickerProvider>
      </TConfirmProvider>
    </ThemeProvider>
  );
}

export default App;
