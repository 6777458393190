import React from "react";
import { Overhead } from "./Overhead";
import { Grid } from "@material-ui/core";

export function ProjectSettings() {
  return (
    <div>
      <Grid container>
        <Grid item xs={6}>
          <Overhead type="employee" />
        </Grid>
        <Grid item xs={6}>
          <Overhead type="contractor" />
        </Grid>
      </Grid>
    </div>
  );
}
