import React, { useState } from "react";
import { Grid } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { EditableCol } from "../table/TableViewData";
import { OverheadRate } from "../../api/Overhead";
import { TableViewDataSimple } from "../table/TableViewDataSimple";
import { api } from "../../api/API";
import { Filter } from "../table/TableFilter";
import { DeleteButton } from "../table/actions/DeleteButton";
import grey from "@material-ui/core/colors/grey";

export const divisionOptions = [
  { label: "Excavating", id: "excavating" },
  { label: "Landscaping", id: "landscaping" },
  { label: "Fencing", id: "fencing" },
];

const cols: EditableCol<OverheadRate>[] = [
  {
    name: "Rate",
    key: "rateCents",
    type: "money-float",
    placeholder: "0.00",
  },
  {
    name: "Division",
    key: "division",
    type: "select-from-list",
    strictList: true,
    listSource: () => Promise.resolve(divisionOptions),
  },
  {
    name: "Effective Starting",
    key: "startDate",
    type: "date",
  },
  {
    name: "Actions",
    actions: true,
    selector: (s) => null,
  },
];

export function Overhead(props: { type: "employee" | "contractor" }) {
  const [selection, setSelection] = useState<number[]>([]);

  const isEmployee = props.type === "employee";
  const remove = isEmployee
    ? () => api.overhead.removeEmployeeRates(selection)
    : () => api.overhead.removeContractorRates(selection);
  const insert = isEmployee
    ? (props: any) => api.overhead.createEmployeeRate(props)
    : (props: any) => api.overhead.createContractorRate(props);
  const update = isEmployee
    ? (props: any) => api.overhead.updateEmployeeRate(props)
    : (props: any) => api.overhead.updateContractorRate(props);
  const list = isEmployee
    ? (props: any) => api.overhead.listEmployeeRates()
    : (props: any) => api.overhead.listContractorRates();

  return (
    <div style={{ borderRight: "1px solid " + grey["300"] }}>
      <div style={{ padding: 16 }}>
        <Grid container justify="space-between">
          {props.type === "employee" ? (
            <Grid item>
              <Typography variant="body1">Employee Overhead Rate</Typography>
              <Typography variant="body2">(per employee hour)</Typography>
            </Grid>
          ) : (
            <Grid item>
              <Typography variant="body1">Contractor Overhead Rate</Typography>
              <Typography variant="body2">(per $ invoiced)</Typography>
            </Grid>
          )}
          <DeleteButton disabled={selection.length === 0} onRemove={remove} />
        </Grid>
      </div>
      <div>
        <TableViewDataSimple
          fetcher={list}
          cols={cols}
          editable={true}
          orderByColumnName="Effective Starting"
          lockOrderBy
          insert={insert}
          update={update}
          filter={blankArray}
          search=""
          onSelectionChange={setSelection}
          noPaginate
        />
      </div>
    </div>
  );
}

const blankArray: Filter[] = [];
