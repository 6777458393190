import { CellChange } from "./EditCell";
import React from "react";
import { Checkbox } from "@material-ui/core";

export function CheckboxCell(props: {
  value: string;
  onChange: CellChange;
  onCancel(): void;
}) {
  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <Checkbox
        checked={props.value.toString() === "true"}
        size="small"
        onChange={(e) => {
          props.onChange(e.target.checked);
        }}
      />
    </div>
  );
}
