import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import Grid from "@material-ui/core/Grid";
import React, { useEffect, useState } from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import grey from "@material-ui/core/colors/grey";
import useDebounce from "use-debounce/lib/useDebounce";

const useStyles = makeStyles((theme) => ({
  search: {
    margin: theme.spacing(1),
    marginLeft: theme.spacing(2),
    minWidth: 350,

    "& .MuiOutlinedInput-input": {
      padding: theme.spacing(1),
    },
    "& .MuiInputAdornment-root": {
      color: grey["500"],
    },
  },
}));

export function TableViewSearch(props: {
  placeholder: string;
  onChange(value: string): void;
}) {
  const styles = useStyles();
  const [search, setSearch] = useState("");
  const [debounced] = useDebounce(search, 500);
  const onChange = props.onChange;

  useEffect(() => {
    onChange(debounced);
  }, [debounced, onChange]);

  return (
    <Grid item>
      <TextField
        className={styles.search}
        variant="outlined"
        placeholder={props.placeholder}
        onChange={(e) => setSearch(e.target.value)}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />
    </Grid>
  );
}
