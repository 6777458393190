import React, { useCallback } from "react";
import { TextCell } from "./TextCell";
import { CellChange } from "./EditCell";

export function FloatCell(props: {
  value: any;
  onCancel(): void;
  onChange: CellChange;
}) {
  const _onChange = props.onChange;

  const onChange = useCallback(
    (value: string, extra?: any) => {
      try {
        const n = parseFloat(value);
        if (isNaN(n)) throw new Error("invalid");

        _onChange(n, extra);
      } catch (e) {
        _onChange(0, extra);
      }
    },
    [_onChange]
  );

  return (
    <TextCell
      align="right"
      value={
        typeof props.value === "number" ? props.value.toFixed(3) : props.value
      }
      onCancel={props.onCancel}
      onChange={onChange}
    />
  );
}
