import React, {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import { TForm } from "./TForm";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import { TActions } from "./TActions";
import { Dialog } from "@material-ui/core";
import DialogContentText from "@material-ui/core/DialogContentText";
import { useAsyncAction } from "nate-react-api-helpers";

interface ConfirmProps {
  title: string;
  text: string;
  onConfirm: () => Promise<any>;
}

const Context = createContext<{
  show: (props: ConfirmProps) => void;
}>({
  show: () => console.error("invalid context"),
});

export function useTConfirm(props: ConfirmProps) {
  const ctx = useContext(Context);
  return useCallback(() => {
    ctx.show(props);
  }, [props, ctx]);
}

export function TConfirmProvider(props: PropsWithChildren<{}>) {
  const [title, setTitle] = useState("");
  const [text, setText] = useState("");
  const [show, setShow] = useState(false);
  const [confirm, setConfirm] = useState<{ callback: () => Promise<any> }>({
    callback: () => new Promise<any>((resolve, reject) => reject("error")),
  });

  const action = useAsyncAction(async () => {
    await confirm.callback();
    setShow(false);
  }, [confirm]);

  const ctx = useMemo(() => {
    return {
      show: (props: ConfirmProps) => {
        setTitle(props.title);
        setText(props.text);
        setConfirm({
          callback: props.onConfirm,
        });
        setShow(true);
      },
    };
  }, []);

  return (
    <>
      <Context.Provider value={ctx}>
        {props.children}
        <Dialog open={show} maxWidth="xs" fullWidth>
          <TForm onSubmit={() => action.callback(null)}>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
              <DialogContentText>{text}</DialogContentText>
              <TActions
                loadingOrError={action.LoadingElement}
                onCancel={() => setShow(false)}
                confirmText="Confirm"
              />
            </DialogContent>
          </TForm>
        </Dialog>
      </Context.Provider>
    </>
  );
}
