import React, { CSSProperties, PropsWithChildren } from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  padding: {
    padding: theme.spacing(2),
    overflow: "auto",
  },
  default: {
    overflow: "auto",
    display: "flex",
    flexDirection: "column",
  },
}));

export function TabbedCardSection(
  props: PropsWithChildren<{
    title: string;
    padded?: boolean;
    style?: CSSProperties;
    disabled?: boolean;
  }>
) {
  const styles = useStyles();
  return (
    <div
      className={props.padded ? styles.padding : styles.default}
      style={props.style}
    >
      {props.children}
    </div>
  );
}
