import React from "react";
import { Container } from "../Container";
import { Header } from "../Header";
import { TLinkButton } from "../TLinkButton";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import { useIsOfficeManager, useIsProjectManager } from "../../misc/Auth";
import { TabbedCardSection } from "../TabbedCardSection";
import { TabbedCard } from "../TabbedCard";
import { ProjectList } from "./ProjectList";
import { CostingSummary } from "./CostingSummary";
import { ProjectSettings } from "./ProjectSettings";
import { InterCompanyInvoicingSummary } from "./InterCompanyInvoicingSummary";

export function ProjectsPages() {
  const isAdmin = useIsOfficeManager();
  const isProjectManger = useIsProjectManager();

  return (
    <Container>
      <Header
        title="Projects"
        breadcrumbs={[{ name: "Projects", path: "/projects" }]}
        actions={
          <TLinkButton
            size="large"
            variant="contained"
            startIcon={<AddCircleOutlineIcon />}
            to="/project/create"
            title="Project"
          />
        }
      />

      <TabbedCard>
        <TabbedCardSection title="Active">
          <ProjectList type="active" />
        </TabbedCardSection>
        <TabbedCardSection title="Pending">
          <ProjectList type="pending" />
        </TabbedCardSection>
        {isAdmin && (
          <TabbedCardSection title="Costing Summary">
            <CostingSummary />
          </TabbedCardSection>
        )}
        {isAdmin && (
          <TabbedCardSection title="Inter-Company Invoicing">
            <InterCompanyInvoicingSummary />
          </TabbedCardSection>
        )}
        <TabbedCardSection title="Archived">
          <ProjectList type="archived" />
        </TabbedCardSection>
        {isAdmin && (
          <TabbedCardSection title="Settings">
            <ProjectSettings />
          </TabbedCardSection>
        )}
      </TabbedCard>
    </Container>
  );
}
