import makeStyles from "@material-ui/core/styles/makeStyles";
import { CellFocusContext, EditableCol } from "../TableViewData";
import React, {
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import TableCell from "@material-ui/core/TableCell";
import { DateCell } from "./DateCell";
import { ValueCell } from "./ValueCell";
import { TextCell } from "./TextCell";
import { SelectFromListCell } from "./SelectFromListCell";
import { MoneyCell } from "./MoneyCell";
import { NumberCell } from "./NumberCell";
import { FloatCell } from "./FloatCell";
import { MoneyFloatCell } from "./MoneyFloatCell";
import { SelectEntityFromListCell } from "./SelectEntityFromListCell";
import { DateTimeCell } from "./DateTimeCell";
import { CheckboxCell } from "./CheckboxCell";
import { TimeCell } from "./TimeCell";

const useStyles = makeStyles((theme) => ({
  tableCell: {
    height: 30,
  },
  checkboxPadding: {
    padding: 0,
  },
}));

export function EditCell<T extends { id: number }>(props: {
  column: EditableCol<T>;
  row: T;
  value: any;
  rowId: any;
  index: number;
  locked?: boolean;
  onChange(cb: (old: Partial<T> | undefined) => Partial<T> | undefined): void;
}) {
  const [editable, setEditable] = useState(false);
  const showEdit = useCallback(() => {
    setEditable(true);
  }, []);

  const focusChangeEmitter = useContext(CellFocusContext);

  useEffect(() => {
    const sub = focusChangeEmitter.subscribe((e) => {
      if (e.index === props.index && e.row === props.rowId) {
        setEditable(true);
      }
    });

    return () => sub.cancel();
  }, [props.index, props.rowId, focusChangeEmitter]);

  const key = props.column.key;

  if (!key) {
    return (
      <Td key="cell">
        <ValueCell row={props.row} column={props.column} value={props.value} />
      </Td>
    );
  }

  if (props.locked) {
    return (
      <Td key="cell">
        <ValueCell
          row={props.row}
          value={props.value}
          column={props.column}
          locked
        />
      </Td>
    );
  }

  if (!editable && props.column.type !== "bool") {
    return (
      <Td key="cell">
        <ValueCell
          row={props.row}
          value={props.value}
          column={props.column}
          onClick={showEdit}
        />
      </Td>
    );
  }

  const defaultEditProps = {
    value: props.value,
    onCancel: () => setEditable(false),
    onChange: (value: any, extra?: { tab?: -1 | 1 }) => {
      setEditable(false);

      const diff: Partial<T> = {};

      if (props.column.type === "select-entity-from-list") {
        if (value) {
          diff[key] = value.id;
          diff[props.column.displayKey] = value.name;
        }
      } else {
        diff[key] = value;
      }

      props.onChange((old) => {
        const newValue = Object.assign({}, old, diff);
        if (props.column.processChange) {
          return props.column.processChange(newValue as T);
        }

        return newValue;
      });

      if (!extra || !extra.tab) return;
      if (extra.tab === 1) {
        focusChangeEmitter.emit({
          row: props.rowId,
          index: props.index + 1,
        });
        return;
      }

      if (extra.tab === -1) {
        focusChangeEmitter.emit({
          row: props.rowId,
          index: props.index - 1,
        });
        return;
      }
    },
  };

  if (props.column.type === "date") {
    return (
      <Td key="cell">
        <DateCell {...defaultEditProps} />
      </Td>
    );
  }

  if (props.column.type === "date-time") {
    return (
      <Td key="cell">
        <DateTimeCell {...defaultEditProps} />
      </Td>
    );
  }

  if (props.column.type === "time") {
    return (
      <Td key="cell">
        <TimeCell {...defaultEditProps} />
      </Td>
    );
  }

  if (props.column.type === "bool") {
    return (
      <Td key="cell">
        <CheckboxCell {...defaultEditProps} />
      </Td>
    );
  }

  if (props.column.type === "money") {
    return (
      <Td key="cell">
        <MoneyCell {...defaultEditProps} />
      </Td>
    );
  }
  if (props.column.type === "money-float") {
    return (
      <Td key="cell">
        <MoneyFloatCell {...defaultEditProps} />
      </Td>
    );
  }
  if (props.column.type === "float") {
    return (
      <Td key="cell">
        <FloatCell {...defaultEditProps} />
      </Td>
    );
  }
  if (props.column.type === "number") {
    return (
      <Td key="cell">
        <NumberCell {...defaultEditProps} />
      </Td>
    );
  }

  if (props.column.type === "select-from-list") {
    return (
      <Td key="cell">
        <SelectFromListCell
          listSource={props.column.listSource}
          strictList={props.column.strictList}
          {...defaultEditProps}
        />
      </Td>
    );
  }

  if (props.column.type === "select-entity-from-list") {
    return (
      <Td key="cell">
        <SelectEntityFromListCell
          name={(props.row[props.column.displayKey] as any) as string}
          listSource={props.column.listSource}
          strictList={props.column.strictList}
          {...defaultEditProps}
        />
      </Td>
    );
  }

  return (
    <Td key="cell">
      <TextCell {...defaultEditProps} />
    </Td>
  );
}

function Td(props: PropsWithChildren<{}>) {
  const styles = useStyles();

  return (
    <TableCell className={styles.tableCell + " " + styles.checkboxPadding}>
      {props.children}
    </TableCell>
  );
}

export type CellChange = (value: any, extra?: { tab?: -1 | 1 }) => void;
