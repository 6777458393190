import React, { useState } from "react";
import moment, { Moment } from "moment";
import { DatePicker } from "@material-ui/pickers";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { CellChange } from "./EditCell";

const useStyles = makeStyles((theme) => ({
  datePicker: {
    fontSize: "0.8rem",
  },
}));

export const dateFormat = "YYYY-MM-DD";

export function DateCell(props: {
  value: string;
  onChange: CellChange;
  onCancel(): void;
}) {
  const [date] = useState<Moment | null>(moment(props.value || undefined));
  const [elRef, setElRef] = useState<null | HTMLDivElement>(null);
  const styles = useStyles();

  return (
    <>
      <DatePicker
        onClose={() => props.onCancel()}
        open={elRef !== null}
        PopoverProps={{
          anchorEl: elRef,
          anchorOrigin: {
            horizontal: "left",
            vertical: "bottom",
          },
          transformOrigin: {
            horizontal: "left",
            vertical: "top",
          },
        }}
        InputProps={{ disableUnderline: true }}
        inputProps={{ className: styles.datePicker }}
        variant="inline"
        format={dateFormat}
        value={date}
        onChange={(e) => {
          if (e == null) return;
          props.onChange(e.startOf("day").toJSON(), {
            tab: 1,
          });
        }}
        autoOk
      />
      <div ref={setElRef}></div>
    </>
  );
}
