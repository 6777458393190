import { Grid } from "@material-ui/core";
import React, { PropsWithChildren } from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  paddingRight: {
    paddingRight: 16,
  },
}));

export function TableActionPanel(props: PropsWithChildren<{}>) {
  const styles = useStyles();

  return (
    <Grid item>
      <div className={styles.paddingRight}>
        <Grid container spacing={1} alignItems="center">
          {props.children}
        </Grid>
      </div>
    </Grid>
  );
}
