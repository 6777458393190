import { SubAPI } from "./SubAPI";
import { DateString } from "./API";

export type Note = {
  id: number;
  note: string;
  createdBy: number;
  createdByName: string;
  createdAt: DateString;
  archived: boolean;
}

export class ProjectNotesAPI extends SubAPI {
  list(props: {
    project: number;
    search: string;
  }) {
    return this.fetcher.get<Note[]>(
      `/api/project-notes/list`,
      props
    );
  }

  create(props: {
    project: number;
    note: string;
  }) {
    return this.fetcher.post<{id: number}>(
        `/api/project-notes/create`,
        props
    );
  }

  update(props: Note) {
    return this.fetcher.post<{}>(
        `/api/project-notes/update`,
        props
    );
  }
}
