import { SubAPI } from "./SubAPI";
import { DateString, Paginated } from "./API";
import { OrderByDirection } from "../pages/table/TableViewData";
import {Cents} from "nate-react-api-helpers";

export class ServiceRecordsAPI extends SubAPI {
  list(props: {
    vehicle?: number;
    equipment?: number;
    status?: string;
    archived?: boolean;
    page: number;
    search: string;
    pageSize: number;
    orderBy: string;
    orderByDirection: OrderByDirection;
  }) {
    return this.fetcher.get<Paginated<ServiceRecord>>("/api/service-records", props);
  }

  get(id: number) {
    return this.fetcher.get<ServiceRecord>("/api/service-record", { id: id });
  }

  create(rec: ServiceRecord) {
    return this.fetcher.post<ServiceRecord>("/api/service-record/create", rec);
  }

  update(rec: ServiceRecord) {
    return this.fetcher.post<ServiceRecord>("/api/service-record/update", rec);
  }

  createCost(input: ServiceCost) {
    return this.fetcher.post<ServiceCost>("/api/service-record/cost/create", input);
  }

  updateCost(input: ServiceCost) {
    return this.fetcher.post<ServiceCost>("/api/service-record/cost/update", input);
  }

  archiveCosts(input: number[]) {
    return this.fetcher.post<void>("/api/service-record/cost/bulk-archive", input);
  }

  listCosts(props: {
    type: CostType;
    page: number;
    serviceRecord: number
    search: string;
    pageSize: number;
    orderBy: string;
    orderByDirection: OrderByDirection;
  }) {
    return this.fetcher.get<Paginated<ServiceCost>>("/api/service-record/costs", props);
  }

  listOutsourceCompanies() {
    return this.fetcher.get<string[]>("/api/service-record/outsource-companies");
  }
}

export type Check = "yes" | "no" | "n/a";

export interface ServiceRecord {
  id: number;

  vehicle: number;
  vehicleName: string;

  equipment: number;
  equipmentName: string;

  odometer: string;
  nextServiceAt: string;
  serviceType: "maintenance" | "repair";
  description: string;

  licensePlateSticker: Check;
  safetySticker: Check;
  fireExtinguisher: Check;
  firstAidKit: Check;
  trafficTriangles: Check;
  safetyBinder: Check;

  division: string;
  cost: Cents;

  serviceDate: DateString;

  createdAt: DateString;
  archived: boolean;

  status: RecordStatus

  serviceRequest?: number;
}

export type RecordStatus = "in-progress" | "completed";

export type CostType = "part" | "labour" | "outsource";

export type ServiceCost = {
  id: number;
  serviceRecord: number;
  archived: boolean;

  costType: CostType;
}

export type ServicePart = ServiceCost & {
  invoiceNo: string;
  description: string;
  qty: number;
  unitCost: Cents;
}

export type ServiceLabour = ServiceCost & {
  mechanic: number;
  mechanicName: string;

  date: DateString;
  totalHrs: number
}

export type ServiceOutsource = ServiceCost & {
  outsourceCompany: string;
  description: string;
  preTaxCost: Cents;
}

export type ServiceCostAll = ServicePart & ServiceLabour & ServiceOutsource;