import { TableRow } from "@material-ui/core";
import React, { useState } from "react";
import TableCell from "@material-ui/core/TableCell";
import Checkbox from "@material-ui/core/Checkbox";
import { EditableCol } from "../TableViewData";
import makeStyles from "@material-ui/core/styles/makeStyles";
import blueGrey from "@material-ui/core/colors/blueGrey";
import LockIcon from "@material-ui/icons/Lock";
import { grey } from "@material-ui/core/colors";
import { Cell, useEditRowHelpers } from "./Cell";

const useStyles = makeStyles((theme) => ({
  rowHighlighted: {
    backgroundColor: blueGrey["100"],
    "& div.computed-cell-value": {
      backgroundColor: "transparent",
    },
  },
  checkboxPadding: {
    padding: "0 12px 0 16px",
    "& .MuiCheckbox-root": {
      padding: 0,
    },
  },
  icon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "1.2rem",
    color: grey["500"],
  },
}));

export function EditableRow<T extends { id: number }>(props: {
  checked: boolean;
  data: T;
  locked: boolean;
  columns: EditableCol<T>[];
  update: (input: T) => Promise<T>;
  onCheckChange(id: number, tf: boolean): void;
}) {
  const styles = useStyles();
  const [row, setRow] = useState<Partial<T>>(props.data);
  const onChange = useEditRowHelpers({
    row: row,
    onRowChange: setRow,
    updateServer: props.update,
  });

  if (!row) return null;

  return (
    <TableRow className={props.checked ? styles.rowHighlighted : undefined}>
      <TableCell key="checkbox" className={styles.checkboxPadding}>
        {props.locked ? (
          <div className={styles.icon}>
            <LockIcon fontSize="inherit" />
          </div>
        ) : (
          <Checkbox
            checked={props.checked}
            onChange={(e) => {
              props.onCheckChange(props.data.id, e.target.checked);
            }}
          />
        )}
      </TableCell>
      {props.columns.map((c, index) => (
        <Cell
          key={index}
          index={index}
          locked={props.locked}
          row={row}
          onChange={onChange}
          column={c}
        />
      ))}
    </TableRow>
  );
}
