import React, { useState } from "react";
import moment, { Moment } from "moment";
import { DateTimePicker } from "@material-ui/pickers";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { CellChange } from "./EditCell";

const useStyles = makeStyles((theme) => ({
  datePicker: {
    fontSize: "0.8rem",
  },
}));

export const timeFormat = "h:mm a";

export function TimeCell(props: {
  value: string;
  onChange: CellChange;
  onCancel(): void;
}) {
  const [date, setDate] = useState<Moment | null>(
    moment(props.value || undefined)
  );
  const [elRef, setElRef] = useState<null | HTMLDivElement>(null);
  const styles = useStyles();

  return (
    <>
      <DateTimePicker
        onClose={() => {
          if (!date) {
            props.onCancel();
            return;
          }

          props.onChange(date.toJSON(), {
            tab: 1,
          });
        }}
        open={elRef !== null}
        PopoverProps={{
          anchorEl: elRef,
          anchorOrigin: {
            horizontal: "left",
            vertical: "bottom",
          },
          transformOrigin: {
            horizontal: "left",
            vertical: "top",
          },
        }}
        InputProps={{ disableUnderline: true }}
        inputProps={{ className: styles.datePicker }}
        variant="inline"
        format={timeFormat}
        value={date}
        openTo="hours"
        fullWidth
        onChange={(e) => {
          if (e == null) return;
          setDate(e.startOf("minute"));
        }}
        autoOk
      />
      <div ref={setElRef}></div>
    </>
  );
}
