import { SubAPI } from "./SubAPI";
import { DateString, Paginated } from "./API";
import { OrderByDirection } from "../pages/table/TableViewData";

export class VehiclesAPI extends SubAPI {
  list(props: {
    type: "active" | "in-active" | "archived";
    page: number;
    search: string;
    pageSize: number;
    orderBy: string;
    orderByDirection: OrderByDirection;
  }) {
    return this.fetcher.get<Paginated<Vehicle>>("/api/vehicles", props);
  }

  get(id: number) {
    return this.fetcher.get<Vehicle>("/api/vehicle", { id: id });
  }

  create(employee: Vehicle) {
    return this.fetcher.post<Vehicle>("/api/vehicle/create", employee);
  }

  update(employee: Vehicle) {
    return this.fetcher.post<Vehicle>("/api/vehicle/update", employee);
  }

  listForAutoComplete() {
    return this.fetcher.get<Vehicle[]>("/api/vehicles/auto-complete");
  }

  removeRates(props: { ids: number[] }) {
    return this.fetcher.post<VehicleRate>("/api/vehicle/rate/archive", props);
  }

  listRates(props: { vehicle: number }) {
    return this.fetcher.get<Paginated<VehicleRate>>(
      "/api/vehicle/rates",
      props
    );
  }

  createRate(props: VehicleRate) {
    return this.fetcher.post<VehicleRate>("/api/vehicle/rate", props);
  }

  updateRate(props: VehicleRate) {
    return this.fetcher.post<VehicleRate>("/api/vehicle/rate/update", props);
  }
}

export interface Vehicle {
  id: number;
  number: string;
  make: string;
  model: string;
  division: string;
  serialNumber: string;
  lastLocation?: string;
  lastLocationCoords?: Point;
  inActiveReason: string;
  inActive: boolean;
  licensePlate: string;
  transponder407: string;
  towingCapacity: string;
  archived: boolean;
}

export interface Point {
  x: number;
  y: number;
}

export interface VehicleRate {
  id: number;
  equipment: number;
  startDate: DateString;
  activeHourlyRateCents: number;
  standbyHourlyRateCents: number;
  archived: boolean;
}
