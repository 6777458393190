import { actionStyle } from "./InsertRow";
import TableCell from "@material-ui/core/TableCell";
import { EditCell } from "./EditCell";
import React, { useCallback } from "react";
import { EditableCol } from "../TableViewData";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { useAsyncAction } from "nate-react-api-helpers";
import { showSnack } from "../../TSnackbar";

const useStyles = makeStyles((theme) => ({
  checkboxPadding: {
    padding: "0 12px 0 16px",
    "& .MuiCheckbox-root": {
      padding: 0,
    },
  },
}));

export function Cell<T extends { id: number }>(props: {
  index: number;
  column: EditableCol<T>;
  row: Partial<T>;
  locked?: boolean;
  onChange(cb: (old: Partial<T> | undefined) => Partial<T> | undefined): void;
}) {
  const c = props.column;
  const row = props.row;
  const styles = useStyles();

  if (c.actions) {
    const style = c.width
      ? Object.assign({}, actionStyle, { width: c.width })
      : actionStyle;
    return (
      <TableCell key="actions" className={styles.checkboxPadding} style={style}>
        {c.selector(row as T)}
      </TableCell>
    );
  }

  return (
    <EditCell
      locked={props.locked || c.editable === false || c.type === "computed"}
      onChange={props.onChange}
      key={c.name}
      row={row as T}
      value={valueSelector(c, row as T)}
      column={c}
      index={props.index}
      rowId={row.id}
    />
  );
}

export function useEditRowHelpers<T extends { id: number }>(props: {
  updateServer: (value: T) => Promise<T>;
  row: Partial<T>;
  onRowChange(value: Partial<T>): void;
}) {
  const row = props.row;
  const onRowChange = props.onRowChange;
  const update = useAsyncAction(
    async (p: { old: T; newValue: T }) => {
      try {
        const result = await props.updateServer(p.newValue);
        props.onRowChange(result);

        showSnack("Row updated!", {
          variant: "success",
        });
      } catch (e) {
        props.onRowChange(p.old);
        showSnack(e.message, {
          variant: "error",
        });
      }
    },
    [props.updateServer, props.onRowChange]
  );

  const updater = update.callback;

  return useCallback(
    (cb: (old: Partial<T> | undefined) => Partial<T> | undefined) => {
      const updated = cb(row);
      if (updated === undefined) return;

      if (shallowEquals(updated, row)) {
        return; // no changes
      }

      updater({ old: row as T, newValue: updated as T });
      onRowChange(updated);
    },
    [row, updater, onRowChange]
  );
}

function valueSelector<T extends { id: number }>(c: EditableCol<T>, row: T) {
  if (!c.key) {
    if (c.type === "computed") return c.selector(row);
    return "";
  }

  if (c.type === "computed") return c.selector(row);

  const v = row[c.key];
  if (v === undefined) return "";
  return v;
}

function shallowEquals(a: any, b: any) {
  for (let i in a) {
    if (a[i] !== b[i]) return false;
  }

  return true;
}
