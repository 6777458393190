import moment, { isMoment } from "moment";

export interface Filter {
  key: string;
  operator: string;
  value: string | moment.Moment;
  displayValue?: string;
}

export function encodeFilter(value: Filter[]): string {
  if (value.length === 0) return "";
  return value
    .map((v) => {
      let strVal = "";
      if (typeof v.value === "string") {
        strVal = v.value;
      } else if (isMoment(v.value)) {
        strVal = v.value.toJSON();
      } else {
        // @ts-ignore
        strVal = v.value.toString();
      }

      return v.key + v.operator + strVal;
    })
    .join(",");
}
