import {SubAPI} from "./SubAPI";
import {OrderByDirection} from "../pages/table/TableViewData";
import {DateString, Paginated} from "./API";
import {ProgressCallback} from "nate-react-api-helpers";

export class DocumentAPI extends SubAPI {
    list(props: {
        project?: number;
        orderBy: string;
        orderByDirection: OrderByDirection;
        search: string;
        archived: boolean;
    }) {
        return this.fetcher.get<Paginated<Doc>>("/api/documents", props);
    }

    update(props: Doc) {
        return this.fetcher.post<Doc>("/api/document", props);
    }

    remove(list: number[]) {
        return this.fetcher.post<Doc>("/api/document/remove", {
            ids: list,
        });
    }

    upload(props: {
        updateFor?: number;
        project?: number;
        file: File;
    }, onProgress: ProgressCallback) {
        debugger;
        return this.fetcher.postFormDataWithProgress<Doc>("/api/document/upload", {
            info: JSON.stringify({project: props.project, updateFor: props.updateFor}),
            file: props.file,
        }, onProgress);
    }

    downloadLink(file: number, revision?: number) {
        const rev = revision === undefined ? "" : "&revision="+revision;
        return "/api/document/download?type=inline&file=" + file + rev;
    }

    download(file: number) {
        window.open("/api/document/download?type=inline&file=" + file, "_blank");
    }

    downloadURL(file: number) {
        return `/api/file?id=${file}&type=inline`
    }
}

export interface Doc {
    id: number;
    title: string;
    createdBy: string;
    createdAt: DateString;
}