import { DatePicker, KeyboardDatePicker } from "@material-ui/pickers";
import React, { PropsWithChildren } from "react";
import { ParsableDate } from "@material-ui/pickers/constants/prop-types";
import MomentUtils from "@date-io/moment";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import makeStyles from "@material-ui/core/styles/makeStyles";
import moment from "moment";

export function TDatePickerProvider(props: PropsWithChildren<{}>) {
  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      {props.children}
    </MuiPickersUtilsProvider>
  );
}

const useStyles = makeStyles((theme) => ({
  paddingBottom: {
    marginBottom: theme.spacing(2),
  },
}));

export function TDatePicker(props: {
  label: string;
  value: ParsableDate;
  variant?: "static" | "inline";
  onChange(value: Date | null): void;
}) {
  const styles = useStyles();
  return (
    <DatePicker
      variant={props.variant}
      className={styles.paddingBottom}
      InputLabelProps={{ shrink: true }}
      label={props.label}
      inputVariant="outlined"
      format="YYYY-MM-DD"
      value={props.value}
      autoOk
      onChange={(value) => {
        if (!value) {
          props.onChange(null);
          return;
        }

        const date = moment(value.toDate()).startOf("date");
        props.onChange(date.toDate());
      }}
    />
  );
}
