import React, { useEffect, useMemo, useState } from "react";
import { TAutoComplete } from "../../TAutoComplete";
import { SelectFromListSource } from "./SelectFromListSource";
import { CellChange } from "./EditCell";

const defaultValueId = -1;
const blankValue = { id: defaultValueId, label: "(blank)", name: "(blank)" };

export function SelectFromListCell(props: {
  value: string;
  strictList?: boolean;
  listSource(): Promise<{ id: string | number; label: string }[]>;
  onCancel(): void;
  onChange: CellChange;
}) {
  const [listSource] = useState(() => new SelectFromListSource());
  const [defaultValue, setDefaultValue] = useState<{ id: any; name: string }>();

  useEffect(() => {
    if (!props.value) {
      setDefaultValue(blankValue);
      return;
    }

    let cancelled = false;

    (async () => {
      try {
        const list = await listSource.get("", props.listSource);
        if (cancelled) return;

        const v = list.filter((v) => v.id === props.value);
        if (v.length === 0) {
          setDefaultValue(
            Object.assign({}, blankValue, {
              name: props.value,
            })
          );
          return;
        }

        setDefaultValue({ id: v[0].id, name: v[0].label });
      } catch (e: any) {
        console.error(e);
        setDefaultValue(
          Object.assign({}, blankValue, {
            name: props.value,
          })
        );
      }
    })();

    return () => {
      cancelled = true;
    };
  }, [listSource, props.listSource, props.value]);

  if (defaultValue === undefined) return null;

  return (
    <TAutoComplete
      autoFocus
      noBottomPadding
      onCancel={() => props.onCancel()}
      freeSolo={!props.strictList}
      fetcher={async (opt) => {
        const srcList = await listSource.get("", props.listSource);
        if (srcList.indexOf(blankValue) === -1) {
          srcList.unshift(blankValue);
        }

        let list = srcList;

        const search = opt.search.toLowerCase();
        if (search !== "") {
          list = list.filter(
            (s) => s.label.toLowerCase().indexOf(search) !== -1
          );
        }

        if (list.length <= 1) {
          const add = srcList.slice(0, 5).filter((s) => list.indexOf(s) === -1);
          list.push(...add);
        }

        return list.map((l) => ({ id: l.id as any, name: l.label }));
      }}
      label=""
      type="standard"
      initialValue={defaultValue}
      onKeyDown={(e) => {
        if (e.key === "Tab") {
          e.preventDefault();

          props.onChange(props.value, {
            tab: e.shiftKey ? -1 : 1,
          });
        }
      }}
      onChange={(v) => {
        let id: any = v.id;

        if (!props.strictList) {
          if (id === defaultValueId) {
            id = v.name;
          }
        }

        props.onChange(id, {
          tab: 1,
        });
      }}
    />
  );
}
