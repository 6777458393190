import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import React from "react";
import { useLocation } from "react-router-dom";
import FilterHdrIcon from "@material-ui/icons/FilterHdr";
import ChevronRight from "@material-ui/icons/ChevronRight";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import grey from "@material-ui/core/colors/grey";
import { useIsOfficeAdmin, useIsOfficeManager } from "../misc/Auth";

const iconColor = { color: grey["600"] };

export function ProjectSidebarItem() {
  const location = useLocation();
  const rootSelected = location.pathname === "/projects";
  const open = location.pathname.startsWith("/project/");

  return (
    <>
      <ListItem button component="a" href="/projects" selected={rootSelected}>
        <ListItemIcon>
          <FilterHdrIcon />
        </ListItemIcon>
        <ListItemText primary="Projects" />
        {open ? (
          <KeyboardArrowDownIcon style={iconColor} />
        ) : (
          <ChevronRight style={iconColor} />
        )}
      </ListItem>
      {open && <ProjectSubItems />}
    </>
  );
}

function ProjectSubItems() {
  const matches = window.location.pathname.match(
    /^\/project\/([0-9]+)(\/([^/?]+))*/
  );
  const isAdmin = useIsOfficeManager();
  const isAssistant = useIsOfficeAdmin();

  if (matches === null) return null;

  const id = matches[1];
  const subPath = matches[3];

  return (
    <>
      <ListItem
        button
        component="a"
        href={`/project/${id}`}
        selected={subPath === undefined}
      >
        <ListItemIcon />
        <ListItemText primary="Summary" />
      </ListItem>
      {(isAssistant || isAdmin) && (
        <ListItem
          button
          component="a"
          href={`/project/${id}/costing`}
          selected={subPath === "costing"}
        >
          <ListItemIcon />
          <ListItemText primary="Costing" />
        </ListItem>
      )}
    </>
  );
}
