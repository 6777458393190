import { SubAPI } from "./SubAPI";
import { DateString } from "./API";
import { Cents } from "nate-react-api-helpers";
import { OrderByDirection } from "../pages/table/TableViewData";
import { Entity } from "../pages/table/editable/SelectEntityFromListCell";

export class LabourCostAPI extends SubAPI {
  list(props: {
    parentId?: number;
    page: number;
    download?: string;
    search: string;
    pageSize: number;
    orderBy: string;
    filter: string;
    orderByDirection: OrderByDirection;
  }) {
    return this.fetchPaginated<LabourCost>(`/api/costing/labour/list`, props);
  }

  remove(props: number[]) {
    return this.fetcher.post<{}>(`/api/costing/labour/remove`, props);
  }

  insert(props: any) {
    return this.fetcher.post<LabourCost>(`/api/costing/labour/create`, props);
  }

  update(props: LabourCost) {
    return this.fetcher.post<LabourCost>(`/api/costing/labour/update`, props);
  }

  utm() {
    return this.fetcher.get<string[]>(`/api/costing/labour/utm-list`);
  }

  vendors() {
    return this.fetcher.get<Entity[]>(`/api/costing/labour/vendor-list`);
  }
}

export interface LabourCost {
  id: number;
  date: DateString;
  employee: number;
  employeeName: string;
  description: string;
  qty: number;
  utm: string;
  division: string;
  unitCostCents: Cents;
  timeSheet: number;
  timeSheetSiteOrTask: number;
  kind: "driver" | "snow" | "site";
  project: number;
}
