import { SubAPI } from "./SubAPI";
import { DateString } from "./API";
import { OrderByDirection } from "../pages/table/TableViewData";
import {Division} from "./EquipmentCosting";

export class VehicleCostAPI extends SubAPI {
  list(props: {
    parentId?: number;
    page: number;
    download?: string;
    search: string;
    pageSize: number;
    orderBy: string;
    filter: string;
    orderByDirection: OrderByDirection;
  }) {
    return this.fetchPaginated<VehicleCost>(`/api/costing/vehicle/list`, props);
  }

  create(props: VehicleCost) {
    return this.fetcher.post<VehicleCost>("/api/costing/vehicle/create", props);
  }

  update(props: VehicleCost) {
    return this.fetcher.post<VehicleCost>("/api/costing/vehicle/update", props);
  }

  remove(props: number[]) {
    return this.fetcher.post<VehicleCost>("/api/costing/vehicle/remove", props);
  }
}

export interface VehicleCost {
  id: number;
  date: DateString;
  vehicle: number;
  vehicleName: string;
  description: string;

  activeHrs: number;
  inActiveHrs: number;
  activeRateCents: number;
  inActiveRateCents: number;

  timeSheet: number;
  timeSheetSiteDetails?: number;
  driverTask?: number;
  kind: "driver" | "snow" | "site";
  project: number;

  division: Division
}
