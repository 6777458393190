import React from "react";
import { Card } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  card: {
    display: "flex",
    flexDirection: "column",
  },
}));

export function TableView(props: {
  tabs: JSX.Element;
  search: JSX.Element;
  moreOptions: JSX.Element;
  table: JSX.Element;
}) {
  const styles = useStyles();
  return (
    <Card className={styles.card}>
      {props.tabs}
      <Grid container justify="space-between" alignItems="center">
        {props.search}
        {props.moreOptions}
      </Grid>
      {props.table}
    </Card>
  );
}
