import React, { useState } from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { useAsyncAction } from "nate-react-api-helpers";
import { api } from "../../api/API";
import { useHistory } from "react-router-dom";
import { TerrainModalFormat } from "./TerrainModalFormat";

const useStyles = makeStyles((theme) => ({
  text: {
    width: 275,
  },
}));

export function Login() {
  const styles = useStyles();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const history = useHistory();

  const login = useAsyncAction(async (input) => {
    await api.employees.login(input);
    // @ts-ignore
    window.location = "/";
  }, []);

  return (
    <TerrainModalFormat
      onSubmit={() => {
        login.callback({
          username,
          password,
        });
      }}
      inputs={
        <>
          <Grid item>
            <TextField
              onChange={(e) => setUsername(e.target.value)}
              className={styles.text}
              label="Email"
            />
          </Grid>
          <Grid item>
            <TextField
              onChange={(e) => setPassword(e.target.value)}
              type="password"
              className={styles.text}
              label="Password"
            />
          </Grid>
        </>
      }
      actions={
        <>
          {login.LoadingElement && (
            <>
              {login.LoadingElement}
              <div style={{ height: 30 }} />
            </>
          )}
          <Grid container justify="space-between">
            <Grid item>
              <Button onClick={() => history.push("/forgot-password")}>
                Forgot Password
              </Button>
            </Grid>
            <Grid item>
              <Button variant="contained" color="primary" type="submit">
                Login
              </Button>
            </Grid>
          </Grid>
        </>
      }
    />
  );
}
