import React, { useEffect, useState } from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { CellChange } from "./EditCell";

const useStyles = makeStyles((theme) => ({
  textField: {
    border: "none",
    width: "100%",
    height: "100%",
    outlineColor: theme.palette.primary.main,
  },
}));

const textStyle = {
  paddingLeft: 16,
  paddingRight: 5,
};

const rightTextStyle = Object.assign({}, textStyle, {
  textAlign: "right",
});

export function TextCell(props: {
  align?: "right";
  value: string;
  onCancel(): void;
  onChange: CellChange;
}) {
  const styles = useStyles();
  const [value, setValue] = useState(props.value);
  const [ref, setRef] = useState<HTMLInputElement | null>(null);
  let changeCalled = false;

  useEffect(() => {
    if (ref === null) return;
    ref.focus();
    ref.select();
  }, [ref]);

  return (
    <input
      ref={setRef}
      type="text"
      defaultValue={props.value}
      onChange={(e) => setValue(e.target.value)}
      onKeyDown={(e) => {
        if (e.key === "Enter") {
          changeCalled = true;
          props.onChange(value);
        }

        if (e.key === "Tab") {
          changeCalled = true;
          e.preventDefault();
          props.onChange(value, {
            tab: e.shiftKey ? -1 : 1,
          });
        }
      }}
      onBlur={() => {
        if (changeCalled) return;
        props.onChange(value);
      }}
      style={props.align === "right" ? rightTextStyle : textStyle}
      className={styles.textField}
    />
  );
}
