import React, { useEffect, useRef, useState } from "react";
import { useAsync2 } from "nate-react-api-helpers";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { TextField } from "@material-ui/core";
import { useDebounce } from "use-debounce";

interface TElement {
  id: number;
  name: string;
}

export function TAutoComplete(props: {
  fetcher: (opt: { search: string }) => Promise<TElement[]>;
  initialValue?: TElement;
  label: string;
  required?: boolean;
  type?: "outlined" | "standard";
  autoFocus?: boolean;
  className?: string;
  noBottomPadding?: boolean;
  shrinkLabel?: boolean;
  freeSolo?: boolean;
  disabled?: boolean;
  resetAfterSelect?: boolean;
  onCancel?(): void;
  onKeyDown?(e: KeyboardEvent): void;
  onChange(value: TElement): void;
}) {
  const [search, setSearch] = useState("");
  const [debounced] = useDebounce(search, 500, { leading: true });
  const fetcher = useAsync2(
    (opt) => props.fetcher(opt),
    { search: debounced },
    [debounced]
  );

  const [hasInitialData, setHasInitialData] = useState(false);

  const loading = fetcher.loading;
  useEffect(() => {
    if (hasInitialData) return;
    if (!loading) {
      setHasInitialData(true);
    }
  }, [hasInitialData, loading]);

  const defaultValue =
    props.initialValue ||
    ({
      id: -1,
      name: "",
    } as TElement);
  const [value, setValue] = useState(defaultValue);
  const changedRef = useRef(false);

  return (
    <>
      <Autocomplete
        value={value}
        loading={fetcher.loading}
        defaultValue={props.initialValue}
        disabled={props.disabled}
        options={fetcher.asList}
        className={props.className}
        onBlur={props.onCancel}
        onInputChange={(event, newInputValue) => {
          setSearch(newInputValue);
        }}
        filterOptions={(options, params) => {
          const filtered = options.slice(0);

          // Suggest the creation of a new value
          if (props.freeSolo && params.inputValue !== "") {
            filtered.push({
              inputValue: params.inputValue,
              id: -1,
              name: `Add "${params.inputValue}"`,
            } as any);
          }

          return filtered;
        }}
        selectOnFocus
        onChange={(e, newValue) => {
          if (newValue === null || newValue === undefined) return;
          if (typeof newValue === "string") {
            return;
          }

          if (newValue.id === -1) {
            const v = {
              id: -1,
              name: (newValue as any).inputValue as string,
            };

            setValue(props.resetAfterSelect ? defaultValue : v);
            props.onChange(v);
            changedRef.current = true;
            return;
          }

          changedRef.current = true;
          setValue(props.resetAfterSelect ? defaultValue : newValue);
          props.onChange(newValue);
        }}
        renderOption={(opt) => opt.name}
        getOptionLabel={(opt) => opt.name || ""}
        getOptionSelected={(opt, value) => opt.id === value.id}
        freeSolo={props.freeSolo}
        blurOnSelect
        renderInput={(params) => {
          const { InputProps, inputProps, InputLabelProps, ...others } = params;

          return (
            <TextField
              {...others}
              inputProps={Object.assign({}, inputProps, {
                onKeyDown: props.onKeyDown as any,
                onFocus: (e: any) => {
                  // @ts-ignore
                  inputProps.onFocus(e);

                  changedRef.current = false;
                  e.target.value = "";

                  // @ts-ignore
                  inputProps.onChange(e);
                },
                onBlur: (e: any) => {
                  if (!changedRef.current) {
                    // force input to revert to original value if no selection has been changed
                    setValue(Object.assign({}, value));
                  }

                  // @ts-ignore
                  inputProps.onBlur(e);
                },
              })}
              onFocus={(e) => {
                const target = e.currentTarget;

                // auto-open option list
                setTimeout(() => {
                  const ev = new KeyboardEvent("keydown", { key: "ArrowDown" });
                  ev.initEvent("keydown", true, false);
                  target.dispatchEvent(ev);
                });
              }}
              InputProps={Object.assign({}, InputProps, {
                disableUnderline: true,
              })}
              InputLabelProps={Object.assign({}, InputLabelProps, {
                shrink: props.shrinkLabel,
              })}
              autoFocus={props.autoFocus}
              required={props.required}
              label={props.label}
              variant={props.type || "outlined"}
            />
          );
        }}
      />
      {props.noBottomPadding ? null : <div style={{ height: 16 }} />}
    </>
  );
}
