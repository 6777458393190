import React, { useMemo, useState } from "react";
import { TAutoComplete } from "../../TAutoComplete";
import { SelectFromListSource } from "./SelectFromListSource";
import { CellChange } from "./EditCell";

export interface Entity {
  id: number;
  label: string;
}

export function SelectEntityFromListCell(props: {
  value: number;
  name: string;
  strictList?: boolean;
  listSource(): Promise<Entity[]>;
  onCancel(): void;
  onChange: CellChange;
}) {
  const defaultValue = useMemo(
    () => ({
      id: props.value,
      name: props.name,
    }),
    [props.value, props.name]
  );

  const [listSource] = useState(() => new SelectFromListSource());

  return (
    <TAutoComplete
      autoFocus
      noBottomPadding
      onCancel={() => props.onCancel()}
      freeSolo={!props.strictList}
      fetcher={async (opt) => {
        const srcList = await listSource.get("", props.listSource);
        let list = srcList;

        const search = opt.search.toLowerCase();
        if (search !== "") {
          list = list.filter(
            (s) => s.label.toLowerCase().indexOf(search) !== -1
          );
        }

        if (list.length === 0) {
          const add = srcList.slice(0, 5).filter((s) => list.indexOf(s) === -1);
          list.push(...add);
        }

        return list.map((l, index) => ({ id: l.id, name: l.label }));
      }}
      label=""
      type="standard"
      initialValue={defaultValue}
      onKeyDown={(e) => {
        if (e.key === "Tab") {
          e.preventDefault();

          props.onChange(undefined, {
            tab: e.shiftKey ? -1 : 1,
          });
        }
      }}
      onChange={(v) =>
        props.onChange(v, {
          tab: 1,
        })
      }
    />
  );
}
