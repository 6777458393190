import { CellChange } from "./EditCell";
import { FloatCell } from "./FloatCell";
import React, { useCallback } from "react";

export function MoneyFloatCell(props: {
  value: any;
  onCancel(): void;
  onChange: CellChange;
}) {
  const propsChange = props.onChange;
  const onChange = useCallback(
    (value, other) => {
      propsChange(value * 100, other);
    },
    [propsChange]
  );

  return (
    <FloatCell
      value={ensurePrecision(props.value / 100, 2)}
      onCancel={props.onCancel}
      onChange={onChange}
    />
  );
}

export function ensurePrecision(value: number, n: number) {
  const toFixed = value.toFixed(n);
  const toString = value.toString();
  if (toFixed.length > toString.length) return toFixed;
  return toString;
}
