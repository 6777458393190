import React, { createContext, PropsWithChildren, useMemo } from "react";
import { EventEmitter } from "nate-react-api-helpers";

export const SelectionContext = createContext({
  selection: () => {
    return [] as number[];
  },
  updateSelection: (selection: number[]): void => {
    throw new Error("missing context");
  },
});

const blankArray: number[] = [];

export function SelectionProvider(props: PropsWithChildren<{}>) {
  const selection = useMemo(() => new EventEmitter<number[]>(), []);
  const ctx = useMemo(() => {
    return {
      selection: () => {
        return (EventEmitter.reactValue(selection) || blankArray) as number[];
      },
      updateSelection: (value: number[]) => {
        selection.emit(value);
      },
    };
  }, [selection]);

  return (
    <SelectionContext.Provider value={ctx}>
      {props.children}
    </SelectionContext.Provider>
  );
}
