import { Card } from "@material-ui/core";
import React, { ReactElement, useEffect, useMemo, useState } from "react";
import { TTabs } from "./TTabs";
import { TSpacer } from "./TSpacer";

function elementsOnly(
  input: Array<ReactElement | boolean>
): Array<ReactElement> {
  return input.filter((i) => i !== true && i !== false) as Array<ReactElement>;
}

export function TabbedCard(props: {
  children: Array<ReactElement | boolean>;
  maxWidth?: number;
  detached?: boolean;
  headerRight?: JSX.Element;
  autoGrow?: boolean;
  onSelectionChange?(key: string): void;
}) {
  const [tab, setTab] = useState("");
  const rawTitles = elementsOnly(props.children)
    .map((c) => ({ name: c.props.title, disabled: c.props.disabled || false }))
    .filter((c) => !!c.name);

  // eslint-disable-next-line
  const titles = useMemo(() => rawTitles, [JSON.stringify(rawTitles)]);

  const visible = elementsOnly(props.children).filter(
    (c) => c.props.title === tab && !c.props.disabled
  );
  const show = visible.length > 0 ? visible[0] : null;
  const title = show?.props.title;

  const onSelectionChange = props.onSelectionChange;
  useEffect(() => {
    if (!onSelectionChange) return;
    onSelectionChange(title);
  }, [title, onSelectionChange]);

  if (props.detached) {
    return (
      <>
        <Card>
          <TTabs
            values={titles}
            onChange={setTab}
            rightAction={props.headerRight}
          />
        </Card>
        <TSpacer />
        {show}
      </>
    );
  }

  return (
    <Card
      style={{
        maxWidth: props.maxWidth,
        display: "flex",
        flexDirection: "column",
        flex: props.autoGrow ? 1 : undefined,
      }}
    >
      <TTabs
        values={titles}
        onChange={setTab}
        rightAction={props.headerRight}
      />
      {show}
    </Card>
  );
}
