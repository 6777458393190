import {SubAPI} from "./SubAPI";
import {DateString} from "./API";
import {TFilter, TSort} from "../pages/reports/ReportProvider";

export class ReportsAPI extends SubAPI {
    getTable(input: { columns: string[]; dateStart: Date; dateEnd: Date }) {
        return this.fetcher.post<{ [k: string]: string | number }[]>(
            "/api/reports/get-table",
            input
        );
    }

    getTimeSeries(input: {
        columns: string[];
        filters: Filters;
        dateStart: Date;
        dateEnd: Date;
    }) {
        return this.fetcher.post<TimeSeriesData>("/api/reports/get-time-series", input);
    }

    get(input: {
        id: number;
    }) {
        return this.fetcher.get<Report>("/api/report", input)
    }

    save(input: Report) {
        return this.fetcher.post<Report>("/api/report", input);
    }

    listReports() {
        return this.fetcher.get<Report[]>("/api/reports/list")
    }

    removeReport(input: {id: number}) {
        return this.fetcher.post("/api/reports/remove", input)
    }
}

export type Report = {
    id: number;
    title: string;
    settings: {
        filters: TFilter;
        columns: string[];
        sort: TSort;
    }
}

export type Sort = {};

export type Filters = { [k: string]: string[] };

type TimeSeriesRow = {
    date: DateString
} & {
    [k: string]: string | null
}

export type TimeSeriesData = TimeSeriesRow[];