import TableRow from "@material-ui/core/TableRow";
import { TableCell } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import { TableViewDataRow } from "./TableViewDataRow";
import { contains } from "nate-react-api-helpers";
import React from "react";
import { Col } from "./TableViewData";
import TableBody from "@material-ui/core/TableBody";

export function TTableBody<T extends { id: number }>(props: {
  loading: boolean;
  error: string | null;
  columns: Col<T>[];
  rows: T[];
  checked: number[];
  onCheckedChange(id: number, value: boolean): void;
  navigateTo?(row: T): string;
}) {
  if (props.loading) {
    return (
      <TableBody>
        <TableRow key="loading">
          <TableCell colSpan={props.columns.length + 1}>
            <CircularProgress />
          </TableCell>
        </TableRow>
      </TableBody>
    );
  }

  if (props.error) {
    return (
      <TableBody>
        <TableRow key="error">
          <TableCell colSpan={props.columns.length + 1}>
            <Typography variant="body1" color="error">
              {props.error}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              Please refresh the page and try again
            </Typography>
          </TableCell>
        </TableRow>
      </TableBody>
    );
  }

  if (props.rows.length === 0) {
    return (
      <TableBody>
        <TableRow key="no-data">
          <TableCell colSpan={props.columns.length + 1}>
            <Typography variant="body1" color="textSecondary">
              No data here...
            </Typography>
          </TableCell>
        </TableRow>
      </TableBody>
    );
  }

  return (
    <TableBody>
      {props.rows.map((r) => (
        <TableViewDataRow
          key={r.id}
          data={r}
          navigateTo={props.navigateTo}
          columns={props.columns}
          onCheckChange={props.onCheckedChange}
          checked={contains(props.checked, r.id)}
        />
      ))}
    </TableBody>
  );
}
