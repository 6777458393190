import { useAuthenticated } from "nate-react-api-helpers";
import React from "react";
import { useLocation } from "react-router-dom";
import { Login } from "./Login";
import Modal from "@material-ui/core/Modal";

export function RequireLogin() {
  const { authenticated } = useAuthenticated();
  const location = useLocation();

  if (authenticated) return null;
  switch (location.pathname) {
    case "/login":
    case "/forgot-password":
      return null;
    default:
      if (location.pathname.startsWith("/reset-password/")) return null;
  }

  return (
    <Modal open={true}>
      <Login />
    </Modal>
  );
}
