import { SubAPI } from "./SubAPI";
import { DateString, Paginated } from "./API";
import {PaginationRequestInput} from "../pages/table/TableViewDataSimple";

export class MobileBlastAPI extends SubAPI {
  listRecipients(props: Partial<PaginationRequestInput>) {
    return this.fetcher.get<Paginated<MobileRecipient>>("/api/mobile-blast/recipients", props as any);
  }

  listHistory(props: Partial<PaginationRequestInput>) {
    return this.fetcher.get<Paginated<BlastHistory>>("/api/mobile-blast/history", props as any);
  }

  send(props: {
    to: number[];
    subject: string;
    body: string;
  }) {
    return this.fetcher.post<void>("/api/mobile-blast/send", props);
  }
}

export type BlastHistory = {
  id: number;
  sentAt: DateString;
  recipients: string;
  subject: string;
  body: string;
  failed: number;
  sent: number;
  pending: number;
}

export type MobileRecipient = {
  id: number;
  name: string;
  division: string;
  activeToday: boolean;
};