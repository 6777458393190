import TableRow from "@material-ui/core/TableRow";
import { TableCell } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import { InsertRow } from "./InsertRow";
import { contains } from "nate-react-api-helpers";
import React from "react";
import { EditableCol } from "../TableViewData";
import TableBody from "@material-ui/core/TableBody";
import { EditableRow } from "./EditableRow";

export function EditableTableBody<T extends { id: number }>(props: {
  loading: boolean;
  error: string | null;
  columns: EditableCol<T>[];
  rows: T[];
  checked: number[];
  rowIsLocked?(row: T): boolean;
  onCheckedChange(id: number, value: boolean): void;
  insert?(value: T): Promise<T>;
  update?(value: T): Promise<T>;
}) {
  if (props.loading) {
    return (
      <TableBody>
        <TableRow key="loading">
          <TableCell colSpan={props.columns.length + 1}>
            <CircularProgress />
          </TableCell>
        </TableRow>
      </TableBody>
    );
  }

  if (props.error) {
    return (
      <TableBody>
        <TableRow key="error">
          <TableCell colSpan={props.columns.length + 1}>
            <Typography variant="body1" color="error">
              {props.error}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              Please refresh the page and try again
            </Typography>
          </TableCell>
        </TableRow>
      </TableBody>
    );
  }

  if (props.rows.length === 0 && props.insert) {
    return (
      <TableBody>
        <InsertRow columns={props.columns} insert={props.insert} />
      </TableBody>
    );
  }

  const isLocked = props.rowIsLocked ? props.rowIsLocked : () => false;

  return (
    <TableBody>
      {props.insert && (
        <InsertRow columns={props.columns} insert={props.insert} />
      )}
      {props.rows.map((r) => (
        <EditableRow
          key={r.id}
          data={r}
          locked={isLocked(r)}
          columns={props.columns}
          onCheckChange={props.onCheckedChange}
          checked={contains(props.checked, r.id)}
          update={props.update || blankUpdate}
        />
      ))}
    </TableBody>
  );
}

function blankUpdate(input: any): Promise<any> {
  return Promise.reject(new Error("no update function given"));
}
