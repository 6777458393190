import AppBar from "@material-ui/core/AppBar";
import Typography from "@material-ui/core/Typography";
import React, { PropsWithChildren } from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { Sidebar } from "./Sidebar";
import { fade, Grid } from "@material-ui/core";
import grey from "@material-ui/core/colors/grey";
import { terrainBrown } from "../App";
import moment from "moment";
import { SelectionProvider } from "./timeSheets/timeSheet/SelectionProvider";

const useStyles = makeStyles((theme) => ({
  appbarWrapper: {
    padding: theme.spacing(0.5),
    paddingLeft: theme.spacing(2),
  },
  row: {
    display: "flex",
    flexDirection: "row",
    alignItems: "stretch",
    flex: 1,
    overflow: "hidden",
  },
  sidebar: {
    width: 200,
  },
  container: {
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },
  contentWrapper: {
    flex: 1,
    display: "flex",
    alignItems: "stretch",
    flexDirection: "column",
    overflow: "auto",
  },
  content: {
    flex: 1,
    display: "flex",
    alignItems: "stretch",
    flexDirection: "column",
    overflow: "auto",
    padding: theme.spacing(2),
  },
  footer: {
    padding: theme.spacing(0.5),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    backgroundColor: fade(terrainBrown, 0.8),
    fontSize: ".9em",
    color: grey["300"],
    "& a": {
      color: "inherit",
    },
  },
}));

export function Container(
  props: PropsWithChildren<{
    maxWidth?: number;
  }>
) {
  const styles = useStyles();

  return (
    <div className={styles.container}>
      <AppBar position="sticky" className={styles.appbarWrapper}>
        <Typography variant="h6">Terrain Group</Typography>
      </AppBar>
      <div className={styles.row}>
        <div className={styles.sidebar}>
          <Sidebar />
        </div>
        <div className={styles.contentWrapper}>
          <div
            className={styles.content}
            style={props.maxWidth ? { maxWidth: props.maxWidth } : undefined}
          >
            <SelectionProvider>{props.children}</SelectionProvider>
          </div>
          <div className={styles.footer}>
            <Grid container justify="space-between">
              <Grid item>
                {moment(process.env.REACT_APP_BUILD_DATE).format("MMM-DD.H")}.
                {process.env.REACT_APP_VERSION}
              </Grid>
              <Grid item>
                Something wrong?{" "}
                <a href="mailto:it@terraingroup.com">it@terraingroup.com</a>
              </Grid>
              <Grid item>
                Built with ♥ by{" "}
                <a href="https://blue-giraffe.ca">Blue Giraffe Software</a>
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
    </div>
  );
}
