import { APIBase } from "nate-react-api-helpers/lib/APIBase";
import { EmployeeAPI } from "./Employees";
import { ProjectAPI } from "./Projects";
import { CustomerAPI } from "./Customers";
import { CostingAPI } from "./Costing";
import { AuditAPI } from "./Audit";
import { LabourCostAPI } from "./LabourCosting";
import { CostSummary } from "./CostSummary";
import { ProjectInvoiceAPI } from "./ProjectInvoice";
import { OverheadAPI } from "./Overhead";
import { VehiclesAPI } from "./Vehicles";
import { EquipmentAPI } from "./Equipment";
import { TimeSheetAPI } from "./TimeSheet";
import { EquipmentCostAPI } from "./EquipmentCosting";
import { VehicleCostAPI } from "./VehicleCosting";
import {InboxAPI} from "./Inbox";
import {MobileBlastAPI} from "./MobileBlast";
import {DocumentAPI} from "./Document";
import {ProjectNotesAPI} from "./ProjectNotes";
import {PayrollAPI} from "./Payroll";
import {ServiceRecordsAPI} from "./ServiceRecords";
import {ReportsAPI} from "./Reports";
import {ServiceRequestsAPI} from "./ServiceRequests";

export class API extends APIBase {
  employees = new EmployeeAPI(this);
  vehicles = new VehiclesAPI(this);
  timeSheets = new TimeSheetAPI(this);
  equipment = new EquipmentAPI(this);
  projects = new ProjectAPI(this);
  customers = new CustomerAPI(this);
  materialCost = new CostingAPI("materials", this);
  rentalCost = new CostingAPI("rentals", this);
  contractorsCost = new CostingAPI("contractors", this);
  labourCost = new LabourCostAPI(this);
  equipmentCost = new EquipmentCostAPI(this);
  vehicleCost = new VehicleCostAPI(this);
  costSummary = new CostSummary(this);
  audit = new AuditAPI(this);
  projectInvoices = new ProjectInvoiceAPI(this);
  overhead = new OverheadAPI(this);
  inbox = new InboxAPI(this);
  mobileBlast = new MobileBlastAPI(this);
  document = new DocumentAPI(this);
  projectNotes = new ProjectNotesAPI(this);
  payroll = new PayrollAPI(this);
  serviceRecords = new ServiceRecordsAPI(this);
  serviceRequests = new ServiceRequestsAPI(this);
  reports = new ReportsAPI(this);

  constructor() {
    super({
      jwtRefreshEndpoint: "/api/auth/refresh",
    });

    this.fetcher.defaultHeaders.set("X-Source", "web");
    this.fetcher.defaultHeaders.set("X-APIVersion", "1.0");
  }

  ping() {
    return this.fetcher.get<Person>("/api/ping");
  }
}

type Role =
  | "office_admin"
  | "project_manager"
  | "office_manager"
  | "driver"
  | "foreman"
  | "labourer"
    | "mechanic";

interface Person {
  id: number;
  firstName: string;
  lastName: string;
  role: Role;
  email: string;
}

export type DateString = string;

export interface Paginated<T> {
  data: T[];
  totalCount: number;
}

export const api = new API();
