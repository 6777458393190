import { SubAPI } from "./SubAPI";
import { DateString } from "./API";
import { OrderByDirection } from "../pages/table/TableViewData";

export class EquipmentCostAPI extends SubAPI {
  list(props: {
    parentId?: number;
    page: number;
    download?: string;
    search: string;
    pageSize: number;
    orderBy: string;
    filter: string;
    orderByDirection: OrderByDirection;
  }) {
    return this.fetchPaginated<EquipmentCost>(
      `/api/costing/equipment/list`,
      props
    );
  }

  create(props: EquipmentCost) {
    return this.fetcher.post<EquipmentCost>(
      "/api/costing/equipment/create",
      props
    );
  }

  update(props: EquipmentCost) {
    return this.fetcher.post<EquipmentCost>(
      "/api/costing/equipment/update",
      props
    );
  }

  remove(props: number[]) {
    return this.fetcher.post<EquipmentCost>(
      "/api/costing/equipment/remove",
      props
    );
  }
}

export type Division = "landscaping" | "excavating" | "fencing";

export interface EquipmentCost {
  id: number;
  date: DateString;
  equipment: number;
  equipmentName: string;
  division: Division;

  activeHrs: number;
  inActiveHrs: number;
  activeRateCents: number;
  inActiveRateCents: number;

  timeSheet: number;
  timeSheetSiteDetails: number;
  kind: "driver" | "snow" | "site";
  project: number;
}
