import { SubAPI } from "./SubAPI";
import { DateString, Paginated } from "./API";
import { OrderByDirection } from "../pages/table/TableViewData";
import {Cents} from "nate-react-api-helpers";
import {RecordStatus} from "./ServiceRecords";

export class ServiceRequestsAPI extends SubAPI {
  list(props: {
    vehicle?: number;
    equipment?: number;
    serviceRecord?: number;
    status?: string

    page: number;
    search: string;
    pageSize: number;
    orderBy: string;
    orderByDirection: OrderByDirection;
  }) {
    return this.fetcher.get<Paginated<ServiceRequest>>("/api/service-requests", props);
  }

  get(id: number) {
    return this.fetcher.get<ServiceRequest>("/api/service-request", { id: id });
  }

  create(rec: {
    files: File[],
    info: Partial<ServiceRequest>
  }) {
    const body: any = {
      info: JSON.stringify(rec.info),
    };

    for(let i = 0; i < rec.files.length; i++) {
      body["file-" + i] = rec.files[i];
    }

    return this.fetcher.postFormData<ServiceRequest>("/api/service-request/create", body);
  }

  update(rec: ServiceRequest) {
    return this.fetcher.post<ServiceRequest>("/api/service-request/update", rec);
  }

  createLinkedServiceRecord(input: {
    id: number;
  }) {
    return this.fetcher.post<{id: number}>("/api/service-request/create-linked-service-record", input);
  }
}

export interface RecordLink {
  id: number;
  recordId: number;
  requestId: number;

  unitName: string;

  recordDescription: string;
  recordStatus: RecordStatus;

  requestDescription: string;
  requestStatus: RequestStatus;
}

export type RequestStatus = 'pending-review'| 'reviewed'| 'in-progress'| 'completed';
export type RecordSeverity = "major" | "minor";

export interface FileItem {
  id: number;
  name: string;
  createdBy: number;
  createdByName: string;
  createdAt: DateString;
}

export interface ServiceRequest {
  id: number;
  unitText: string;
  unitVehicle?: number;
  unitEquipment?: number;
  issueDescription: string;
  errorCode: string;
  createdAt: DateString;
  createdBy: number;
  createdByName: string;
  severity: RecordSeverity;
  mechanicsNotes: string;

  reviewedBy?: number;
  reviewedByName?: string;
  reviewedAt?: DateString;

  status: string;
  archived: boolean;
  linkedRecords?: RecordLink[]
  files: FileItem[]
}

export type CostType = "part" | "labour" | "outsource";

export type ServiceCost = {
  id: number;
  serviceRecord: number;
  archived: boolean;

  costType: CostType;
}

export type ServicePart = ServiceCost & {
  invoiceNo: string;
  description: string;
  qty: number;
  unitCost: Cents;
}

export type ServiceLabour = ServiceCost & {
  mechanic: number;
  mechanicName: string;

  date: DateString;
  totalHrs: number
}

export type ServiceOutsource = ServiceCost & {
  outsourceCompany: string;
  description: string;
  preTaxCost: Cents;
}

export type ServiceCostAll = ServicePart & ServiceLabour & ServiceOutsource;