import React from "react";
import { Button } from "@material-ui/core";
import { Link } from "react-router-dom";

export function TLinkButton(props: {
  title: string;
  to: string;
  size?: "small" | "large";
  variant?: "text" | "contained";
  startIcon?: JSX.Element;
}) {
  return (
    <Link to={props.to} style={{ textDecoration: "none" }}>
      <Button
        startIcon={props.startIcon}
        variant={props.variant || "text"}
        size={props.size || "small"}
        color="primary"
      >
        {props.title}
      </Button>
    </Link>
  );
}
