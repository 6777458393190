import React, {
  createContext,
  PropsWithChildren,
  useCallback,
  useMemo,
  useState,
} from "react";

interface RegisterInput {
  download(fileName: string): Promise<any>;
  reload(): void;
}

interface Ctx {
  download(fileName: string): Promise<any>;
  reload(): void;
  registerTable(opts: RegisterInput): void;
}

export const TableContext = createContext<Ctx>({
  download(fileName: string) {
    throw new Error("invalid context");
  },
  reload() {
    throw new Error("invalid context");
  },
  registerTable() {
    throw new Error("invalid context");
  },
});

interface CallbackState<T> {
  callback: T;
}
const defaultCallback = {
  callback: () => {
    throw new Error("no reload set");
  },
};

export function TableProvider(props: PropsWithChildren<{}>) {
  const [reload, setReload] = useState<CallbackState<() => void>>(
    defaultCallback
  );
  const [download, setDownload] = useState<
    CallbackState<(value: string) => Promise<any>>
  >(defaultCallback);

  const register = useCallback(
    (cb: RegisterInput) => {
      setReload({
        callback: cb.reload,
      });
      setDownload({
        callback: cb.download,
      });
    },
    [setReload]
  );

  const ctx = useMemo(
    () => ({
      reload: reload.callback,
      download: download.callback,
      registerTable: register,
    }),
    [reload, download, register]
  );

  return (
    <TableContext.Provider value={ctx}>{props.children}</TableContext.Provider>
  );
}
