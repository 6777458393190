import { api } from "../../api/API";
import React, { useState } from "react";
import { Col } from "../table/TableViewData";
import {
  InterCompanyInvoiceSummary,
  ProjectCostSummary,
} from "../../api/Projects";
import { TLinkButton } from "../TLinkButton";
import { TableViewDataAsync } from "../table/TableViewDataAsync";
import Grid from "@material-ui/core/Grid";
import { TableViewSearch } from "../table/TableViewSearch";
import { formatCents, range } from "nate-react-api-helpers";
import { formatCentsAccountingStyle } from "./AccountingSummary";
import moment from "moment";
import { DatePicker } from "@material-ui/pickers";
import { MenuItem, Select } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { ucFirst } from "../timeSheets/timeSheet/TimeSheetDriverTaskList";
import { DownloadButton } from "../table/actions/DownloadButton";
import { TableActionPanel } from "../table/actions/TableActionPanel";
import { TableProvider } from "../table/TableProvider";

const cols: Col<InterCompanyInvoiceSummary>[] = [
  {
    name: "#",
    selector: (e) => e.projectCode,
  },
  {
    name: "Site",
    selector: (e) => e.siteName,
  },
  {
    name: "Division",
    selector: (e) => ucFirst(e.division),
  },
  {
    name: "Labour",
    selector: (e) => formatCents(e.labour),
  },
  {
    name: "Equipment",
    selector: (e) => formatCents(e.equipment),
  },
  {
    name: "Vehicle",
    selector: (e) => formatCents(e.vehicle),
  },
  {
    name: "Actions",
    actions: true,
    selector: (e) => <TLinkButton title="View" to={"/project/" + e.id} />,
  },
];

const useStyles = makeStyles(() => ({
  select: {
    "& .MuiOutlinedInput-input": {
      paddingTop: 8,
      paddingBottom: 8,
    },
  },
}));

export function InterCompanyInvoicingSummary(props: {}) {
  const [search, setSearch] = useState("");
  const [selection, setSelection] = useState<number[]>([]);
  const styles = useStyles();
  console.debug(selection);

  const [month, setMonth] = useState(() => moment().format("YYYY-MMM"));

  return (
    <>
      <Grid container justify="space-between" alignItems="center">
        <TableViewSearch placeholder="Search Projects" onChange={setSearch} />
        <TableActionPanel>
          <DownloadButton tableName={month + "-inter-company-summary"} />

          <Grid item>
            <Select
              className={styles.select}
              value={month}
              style={{}}
              variant="outlined"
              onChange={(e) => setMonth(e.target.value as string)}
            >
              {range(-24, 2).map((r) => {
                const value = moment().utc().startOf("month");
                value.add(r, "month");
                const str = value.format("YYYY-MMM");
                return (
                  <MenuItem key={r.toString()} value={str}>
                    {str}
                  </MenuItem>
                );
              })}
            </Select>
          </Grid>
        </TableActionPanel>
      </Grid>
      <TableViewDataAsync
        key={month}
        cols={cols}
        search={search}
        onSelectionChange={setSelection}
        navigateTo={(row) => "/project/" + row.id}
        fetcher={(opts) =>
          api.projects.interCompanyInvoicing({
            month: month,
            pageSize: opts.pageSize,
            page: opts.page,
            search: opts.search,
            orderBy: opts.orderBy,
            orderByDirection: opts.orderByDirection,
          })
        }
      />
    </>
  );
}
