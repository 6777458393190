import { SubAPI } from "./SubAPI";
import {DateString, Paginated} from "./API";
import {PaginationRequestInput} from "../pages/table/TableViewDataSimple";

export type PayrollPerson = {
  id: number;
  person: number;
  personName: string;
  division: string;
  standardHours: number;
  overtimeHours: number;
  adjustBankHoursBy: number
  bankBalancePre: number;
  addBankHrs: number;
  useBankHrs: number;
  note: string;
}

export type PayrollTimeDetail = {
  id: number;
  description: string;
  wage: number;
  hours: number;
  timeSheet: number;
  timeSheetKind: string;
  project: number;
  siteName?: string;
  site?: number;
  lunch: boolean;
  startTime: DateString;
  endTime?: DateString | null;
}

export type Period = {
  id: number;
  periodStart: DateString;
  periodEnd: DateString;
  standardHours: number;
  overtimeHours: number;
  addBankHours: number;
  useBankHours: number;
  totalHours: number;
}

export type BankTransaction = {
  id: number;
  periodStart: DateString;
  periodEnd: DateString;
  adjustBankHoursBy: number;
  bankBalancePre: number;
  note: string;
}

export class PayrollAPI extends SubAPI {

  setBankBalance(props: {
    person: number;
    newValue: number;
    note: string;
  }) {
    return this.fetcher.post<{}>("/api/payroll/set-bank-balance", props)
  }

  listBankTransactions(props: PaginationRequestInput & {employee: number}) {
    return this.fetcher.get<Paginated<BankTransaction>>("/api/payroll/bank-transactions", props as any)
  }

  listPeriods(props: {
    pageSize: number;
    page: number;
  }) {
    return this.fetcher.get<Paginated<Period>>("/api/payroll/periods", props)
  }

  listPeople(props: {
    period?: number;
    search: string;
    orderBy: string;
    orderByDirection: string;
  }) {
    return this.fetcher.get<PayrollPerson[]>(
      `/api/payroll/list-people-in-period`,
      props
    );
  }

  getCurrent() {
    return this.fetcher.get<{
      id: number,
      approvedBy: {name: string; id: number}[];
      pendingApprovalBy: {name: string; id: number}[];
      startDate?: DateString
      endDate?: DateString
    }>(
        `/api/payroll/current`
    );
  }

  approve() {
    return this.fetcher.post<{}>(
        `/api/payroll/approve`,
        {}
    );
  }

  downloadQuickbooks(period: number) {
    window.open("/api/payroll/quickbooks-download?period=" + period, "_blank");
  }

  update(props: PayrollPerson) {
    return this.fetcher.post<PayrollPerson>(
        `/api/payroll/update-person`,
        props
    );
  }

  listTimes(props: {
    person: number;
    period?: number;
  }) {
    return this.fetcher.get<PayrollTimeDetail[]>(
        `/api/payroll/times-for-person-in-period`,
        props
    );
  }
}
