import { TableCell } from "@material-ui/core";
import React, { useCallback } from "react";
import { OrderByDirection } from "./TableViewData";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  iconCell: {
    padding: "0 12px 0 16px",
  },
  icon: {
    position: "relative",
    top: 3,
    paddingRight: theme.spacing(1),
  },
  iconCellDiv: {
    display: "flex",
    alignItems: "center",
  },
  cellWithHover: {
    cursor: "pointer",

    "&:hover": {
      backgroundColor: "#f2f5ee",
    },
  },
}));

export function TableViewColumnHeader(props: {
  name: string;
  width?: number;
  orderBy: OrderByDirection | null;
  sortable: boolean;
  onOrderByChange(column: string, direction: OrderByDirection): void;
}) {
  const styles = useStyles();
  const { name, orderBy, onOrderByChange } = props;

  const onClick = useCallback(() => {
    if (orderBy === null) {
      onOrderByChange(name, "asc");
      return;
    }

    onOrderByChange(name, orderBy === "desc" ? "asc" : "desc");
  }, [name, orderBy, onOrderByChange]);

  if (props.orderBy !== null) {
    return (
      <TableCell
        variant="head"
        onClick={onClick}
        className={styles.cellWithHover + " " + styles.iconCell}
        style={
          props.width ? { width: props.width } : { width: "auto !important" }
        }
      >
        <div className={styles.iconCellDiv}>
          <div className={styles.icon}>
            {props.orderBy === "asc" ? (
              <ArrowDownwardIcon />
            ) : (
              <ArrowUpwardIcon />
            )}
          </div>
          {props.name}
        </div>
      </TableCell>
    );
  }

  return (
    <TableCell
      variant="head"
      style={
        props.width ? { width: props.width } : { width: "auto !important" }
      }
      onClick={props.sortable ? onClick : undefined}
      className={props.sortable ? styles.cellWithHover : undefined}
    >
      {props.name}
    </TableCell>
  );
}
