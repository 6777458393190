import { Grid, Link } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { useTitle } from "react-use";

const useStyles = makeStyles((theme) => ({
  breadcrumbs: {
    fontSize: "1em",
  },
  spacer: {
    height: 10,
  },
}));

export function Header(props: {
  title: string | JSX.Element;
  breadcrumbs: { name: string; path: string }[];
  actions?: JSX.Element | null;
}) {
  const styles = useStyles();
  useTitle(
    "Terrain Group | " + props.breadcrumbs.map((b) => b.name).join(" / ")
  );

  return (
    <>
      <Grid
        container
        direction="row"
        justify="space-between"
        alignItems="center"
      >
        <Grid item>
          {typeof props.title === "string" ? (
            <Typography variant="h5">{props.title}</Typography>
          ) : (
            props.title
          )}
          <Breadcrumbs className={styles.breadcrumbs}>
            <Link href="/" color="inherit">
              Home
            </Link>
            {props.breadcrumbs.map((b, index) => {
              if (index === props.breadcrumbs.length - 1) {
                return (
                  <Typography
                    key={b.path}
                    style={{ fontSize: "inherit" }}
                    color="textPrimary"
                  >
                    {b.name}
                  </Typography>
                );
              }

              return (
                <Link key={b.path} href={b.path} color="inherit">
                  {b.name}
                </Link>
              );
            })}
          </Breadcrumbs>
        </Grid>
        <Grid item>{props.actions}</Grid>
      </Grid>
      <div className={styles.spacer} />
    </>
  );
}
