import { api } from "../../api/API";
import React, { useState } from "react";
import { Col } from "../table/TableViewData";
import { ProjectCostSummary } from "../../api/Projects";
import { TLinkButton } from "../TLinkButton";
import { TableViewDataAsync } from "../table/TableViewDataAsync";
import Grid from "@material-ui/core/Grid";
import { TableViewSearch } from "../table/TableViewSearch";
import { formatCents } from "nate-react-api-helpers";
import { formatCentsAccountingStyle } from "./AccountingSummary";

const cols: Col<ProjectCostSummary>[] = [
  {
    name: "#",
    selector: (e) => e.projectCode,
  },
  {
    name: "Site",
    selector: (e) => e.siteName,
  },
  {
    name: "Customer",
    selector: (e) => e.customer,
  },
  {
    name: "Revenue",
    selector: (e) => formatCents(e.totalRevenueCents),
  },
  {
    name: "Direct Cost",
    selector: (e) => formatCents(e.totalDirectCostCents),
  },
  {
    name: "OH Cost",
    selector: (e) => formatCents(e.totalOhCostCents),
  },
  {
    name: "Total Cost",
    selector: (e) => formatCents(e.totalCostCents),
  },
  {
    name: "Profit (Loss)",
    selector: (e) => formatCentsAccountingStyle(e.profitCents),
  },
  {
    name: "Profit Margin",
    selector: (e) => e.profitMargin + "%",
  },
  {
    name: "Maint HB Outstanding",
    selector: (e) => formatCents(e.maintHbCents),
  },
  {
    name: "Stat HB Outstanding",
    selector: (e) => formatCents(e.statHbCents),
  },
  {
    name: "Actions",
    actions: true,
    selector: (e) => <TLinkButton title="View" to={"/project/" + e.id} />,
  },
];

export function CostingSummary(props: {}) {
  const [search, setSearch] = useState("");
  const [selection, setSelection] = useState<number[]>([]);
  console.debug(selection);

  return (
    <>
      <Grid container justify="space-between" alignItems="center">
        <TableViewSearch placeholder="Search Projects" onChange={setSearch} />
        <Grid item></Grid>
      </Grid>
      <TableViewDataAsync
        cols={cols}
        search={search}
        onSelectionChange={setSelection}
        navigateTo={(row) => "/project/" + row.id}
        fetcher={(opts) =>
          api.projects.costSummary({
            pageSize: opts.pageSize,
            page: opts.page,
            search: opts.search,
            orderBy: opts.orderBy,
            orderByDirection: opts.orderByDirection,
          })
        }
      />
    </>
  );
}
