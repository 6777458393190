import React, { createContext, useCallback, useEffect, useState } from "react";
import { Table, TableCell } from "@material-ui/core";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead/TableHead";
import Checkbox from "@material-ui/core/Checkbox";
import { distinct, EventEmitter } from "nate-react-api-helpers";
import { TableViewColumnHeader } from "./TableViewColumnHeader";
import { EditableTableBody } from "./editable/EditableTableBody";
import { TTableBody } from "./TTableBody";
import TableContainer from "@material-ui/core/TableContainer";
import makeStyles from "@material-ui/core/styles/makeStyles";
import TablePagination from "@material-ui/core/TablePagination";
import grey from "@material-ui/core/colors/grey";

export interface Col<T extends { id: number }> {
  name: string;
  actions?: boolean;
  width?: number;
  notSortable?: boolean;
  sortable?: boolean;
  selector: (row: T) => React.ReactChild | null;
  align?: "left" | "right";
  noWrap?: boolean;
}

interface _EditableCol<T extends { id: number }> {
  key?: keyof T;
  name: string;
  actions?: boolean;
  placeholder?: string;
  align?: "left" | "right";
  width?: number;
  processChange?(row: T): T;
}

export type EditableCol<T extends { id: number }> = _EditableCol<T> &
  (
    | {
        type?: "number" | "money" | "float" | "money-float" | "bool";
      }
    | {
        type: "date" | "date-time" | "time";
        displayFormat?: string;
      }
    | {
        type: "computed";
        selector: (row: T) => React.ReactChild | null;
      }
    | {
        type: "select-from-list";
        filterable?: boolean;
        strictList?: boolean;
        displayKey?: keyof T;
        listSource: () => Promise<{ label: string; id: number | string }[]>;
      }
    | {
        type: "select-entity-from-list";
        filterable?: boolean;
        strictList?: boolean;
        displayKey: keyof T;
        listSource: () => Promise<{ label: string; id: number }[]>;
      }
  ) &
  (
    | {
        actions: true;
        selector: (row: T) => React.ReactChild | null;
        width?: number;
      }
    | {
        actions?: false;
      }
  ) & {
    editable?: boolean;
    sortable?: boolean;
  };

export async function normalizeListSourceP(
  list: Promise<string[]>
): Promise<{ label: string; id: number | string }[]> {
  return normalizeListSource(await list);
}

export function normalizeListSource(
  list: string[]
): { label: string; id: number | string }[] {
  return list.map((l) => ({ label: l, id: l }));
}

export type OrderByDirection = "asc" | "desc";

type FocusChange = {
  row: any;
  index: number;
};

export const CellFocusContext = createContext<EventEmitter<FocusChange>>(
  new EventEmitter<FocusChange>()
);

const useStyles = makeStyles((theme) => ({
  container: {
    flex: 1,
    overflow: "auto",
    borderTop: "1px solid " + grey["300"],

    "& thead > tr > td": {
      position: "sticky",
      top: 0,
      backgroundColor: "white",
      zIndex: 2,

      "&::after": {
        position: "absolute",
        content: "' '",
        bottom: 0,
        left: 0,
        right: 0,
        height: 1,
        zIndex: 4,
        backgroundColor: grey["300"],
      },
    },
  },
  pagination: {
    display: "flex",
    justifyContent: "flex-end",
    borderTop: "1px solid " + grey["300"],
  },
}));

export function TableViewData<T extends { id: number }>(props: {
  columns: Col<T>[];
  rows: T[];
  editable?: boolean;
  rowsPerPage: number;
  page: number;
  totalCount: number;
  loading: boolean;
  error: string | null;
  orderBy: string;
  orderByDirection: OrderByDirection;
  noPaginate?: boolean;
  rowIsLocked?(value: T): boolean;
  insert?(value: T): Promise<T>;
  update?(value: T): Promise<T>;
  navigateTo?(row: T): string;
  onOrderByChange(column: string, direction: OrderByDirection): void;
  onSelectionChanged(list: number[]): void;
  onPageChange(page: number): void;
}) {
  const styles = useStyles();
  const [checked, setChecked] = useState<number[]>([]);

  const allChecked =
    props.totalCount !== 0 && props.totalCount === checked.length;
  const someChecked =
    props.totalCount !== 0 && !allChecked && checked.length > 0;

  const checkChange = useCallback((id: number, value: boolean) => {
    if (value) {
      setChecked((old) => [...old, id]);
    } else {
      setChecked((old) => old.filter((f) => f !== id));
    }
  }, []);

  const rows = props.rows;

  // only select rows that are actually present
  useEffect(() => {
    if (rows.length === 0) return;

    const validIds = rows.map((r) => r.id);

    setChecked((old) => {
      return old.filter((id) => validIds.indexOf(id) !== -1);
    });
  }, [rows]);

  const onSelectionChanged = props.onSelectionChanged;

  const [focusChangeEmitter] = useState<EventEmitter<FocusChange>>(
    () => new EventEmitter()
  );

  useEffect(() => {
    onSelectionChanged(checked);
  }, [checked, onSelectionChanged]);

  return (
    <>
      <TableContainer className={styles.container}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell key="checkbox" padding="checkbox">
                <Checkbox
                  value="1"
                  checked={allChecked}
                  indeterminate={someChecked}
                  onClick={() => {
                    let rows = props.rows;

                    if (props.rowIsLocked) {
                      const isLocked = props.rowIsLocked;
                      rows = props.rows.filter((row) => !isLocked(row));
                    }

                    if (checked.length < rows.length) {
                      setChecked(
                        distinct([...checked, ...rows.map((r) => r.id)])
                      );
                      return;
                    }

                    if (checked.length === rows.length) {
                      setChecked([]);
                      return;
                    }

                    setChecked(rows.map((r) => r.id));
                  }}
                />
              </TableCell>
              {props.columns.map((c) => (
                <TableViewColumnHeader
                  key={c.name}
                  onOrderByChange={props.onOrderByChange}
                  sortable={
                    !c.actions && c.notSortable !== true && c.sortable !== false
                  }
                  name={c.name}
                  orderBy={
                    props.orderBy === c.name ? props.orderByDirection : null
                  }
                  width={c.width}
                />
              ))}
            </TableRow>
          </TableHead>
          {props.editable ? (
            <CellFocusContext.Provider value={focusChangeEmitter}>
              <EditableTableBody
                loading={props.loading}
                error={props.error}
                checked={checked}
                rowIsLocked={props.rowIsLocked}
                onCheckedChange={checkChange}
                rows={props.rows}
                columns={props.columns as EditableCol<T>[]}
                insert={props.insert}
                update={props.update}
              />
            </CellFocusContext.Provider>
          ) : (
            <TTableBody
              loading={props.loading}
              error={props.error}
              checked={checked}
              onCheckedChange={checkChange}
              navigateTo={props.navigateTo}
              rows={props.rows}
              columns={props.columns}
            />
          )}
        </Table>
      </TableContainer>
      {!props.noPaginate && (
        <div className={styles.pagination}>
          <TablePagination
            rowsPerPageOptions={[props.rowsPerPage]}
            count={props.totalCount}
            onChangePage={(e, page) => props.onPageChange(page)}
            page={props.page}
            rowsPerPage={props.rowsPerPage}
          />
        </div>
      )}
    </>
  );
}
