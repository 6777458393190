import { SubAPI } from "./SubAPI";
import { DateString, Paginated } from "./API";
import { OrderByDirection } from "../pages/table/TableViewData";

export class EquipmentAPI extends SubAPI {
  list(props: {
    type: "active" | "in-active" | "archived";
    page: number;
    search: string;
    pageSize: number;
    orderBy: string;
    orderByDirection: OrderByDirection;
  }) {
    return this.fetcher.get<Paginated<Equipment>>("/api/equipments", props);
  }

  listForAutoComplete() {
    return this.fetcher.get<Equipment[]>("/api/equipments/auto-complete");
  }

  get(id: number) {
    return this.fetcher.get<Equipment>("/api/equipment", { id: id });
  }

  create(employee: Equipment) {
    return this.fetcher.post<Equipment>("/api/equipment/create", employee);
  }

  update(employee: Equipment) {
    return this.fetcher.post<Equipment>("/api/equipment/update", employee);
  }

  removeRates(props: { ids: number[] }) {
    return this.fetcher.post<EquipmentRate>(
      "/api/equipment/rate/archive",
      props
    );
  }

  listRates(props: { equipment: number }) {
    return this.fetcher.get<Paginated<EquipmentRate>>(
      "/api/equipment/rates",
      props
    );
  }

  createRate(props: EquipmentRate) {
    return this.fetcher.post<EquipmentRate>("/api/equipment/rate", props);
  }

  updateRate(props: EquipmentRate) {
    return this.fetcher.post<EquipmentRate>(
      "/api/equipment/rate/update",
      props
    );
  }
}

export interface EquipmentRate {
  id: number;
  equipment: number;
  startDate: DateString;
  activeHourlyRateCents: number;
  standbyHourlyRateCents: number;
  archived: boolean;
}

export interface Equipment {
  id: number;
  number: string;
  make: string;
  model: string;
  division: string;
  serialNumber: string;

  unitWidth: string;
  unitHeight: string;
  unitLength: string;
  liftCapacity: string;
  weight: string;

  lastLocation?: string;
  lastLocationCoords?: Point;
  inActiveReason: string;
  inActive: boolean;
  archived: boolean;
}

export interface Point {
  x: number;
  y: number;
}
