import { SubAPI } from "./SubAPI";
import {DateString, Paginated } from "./API";
import { OrderByDirection } from "../pages/table/TableViewData";
import {Cents, orderByDescending} from "nate-react-api-helpers";
import { AutoCompleteItem } from "./Customers";
import {Division} from "./EquipmentCosting";
import {EmployeeForAutoComplete} from "./Employees";

export class ProjectAPI extends SubAPI {
  interCompanyCosts(props: Partial<{
    page: number;
    search: string;
    pageSize: number;
    orderBy: string;
    orderByDirection: OrderByDirection;
    parentId?: number;
  }>) {
    return this.fetcher.get<Paginated<InterCompanyCost>>("/api/project/inter-company-cost", props);
  }

  listForAutoComplete() {
    return this.fetcher.get<{id: number; name: string;}[]>(
        "/api/projects/auto-complete",
        {}
    );
  }

  list(props: Partial<{
    page: number;
    search: string;
    type: "active" | "pending" | "archived";
    pageSize: number;
    orderBy: string;
    orderByDirection: OrderByDirection;
  }>) {
    return this.fetcher.get<Paginated<Project>>("/api/projects", props);
  }

  getAddressMeta(props: { address: string }) {
    return this.fetcher.get<{
      driveTimeHrs: number;
      hospitalAddress: string;
      siteCoords?: {x: number; y: number}
    }>("/api/project/get-address-meta", props);
  }

  autoComplete(props: { search: string }) {
    return this.fetcher.get<AutoCompleteItem[]>(
      "/api/projects/autocomplete",
      props
    );
  }

  get(id: number) {
    return this.fetcher.get<Project>("/api/project", { id: id });
  }

  create(input: Omit<Project, "id">) {
    return this.fetcher.post<{ id: number }>("/api/project/create", input);
  }

  update(input: Project) {
    return this.fetcher.post<Project>("/api/project/update", input);
  }

  getAccountingSummary(input: { id: number }) {
    return this.fetcher.get<ProjectAccountingSummary>(
      "/api/project/accounting-summary",
      input
    );
  }

  costSummary(input: {}) {
    return this.fetchPaginated<ProjectCostSummary>(
      "/api/project/costing-summary",
      input
    );
  }

  interCompanyInvoicing(input: {
    month: string // YYYY-MMM
    page: number;
    search: string;
    pageSize: number;
    orderBy: string;
    orderByDirection: OrderByDirection;
  }) {
    return this.fetchPaginated<InterCompanyInvoiceSummary>("/api/projects/inter-company-invoicing", input);
  }

  photos(input: {
    project: number;
  }) {
    return this.fetcher.get<Photo[]>("/api/project/photos", input)
  }
}

export type Photo = {
  id: number;
  createdAt: string;
  createdBy: number;
  createdByName: string;
  file: number;
  timeSheet: number;
  timeSheetKind: string;
  timeSheetSiteDetail: number;
}

export type InterCompanyInvoiceSummary = {
  id: number;
  project: number;
  siteName: string;
  projectCode: string;
  division: string;
  labour: Cents;
  equipment: Cents;
  vehicle: Cents;
}

export interface ProjectCostSummary {
  id: number;
  projectCode: string;
  siteName: string;
  customer: string;
  totalRevenueCents: Cents;
  totalCostCents: Cents;
  profitCents: Cents;
  profitMargin: number;
  totalDirectCostCents: Cents;
  totalOhCostCents: Cents;
  maintHbCents: Cents;
  statHbCents: Cents;
}

export interface ProjectAccountingSummary {
  totalRevenueCents: Cents;
  totalCostCents: Cents;
  profitCents: Cents;
  profitMargin: number;
}

export interface Project {
  id: number;
  projectCode: string;
  siteName: string;
  siteAddress: string;
  nearestHospitalAddress: string;
  engineer: string;
  customer: string;
  customerId: number;
  division: Division;
  projectManagerIds: number[];
  projectManagers: {
    id: number;
    firstName: string;
    lastName: string;
  }[];
  siteCoords: any;
  active: boolean;
  timeToShopHrs: number;
  archived: boolean;
}

export type InterCompanyCost = {
  id: number
  date: DateString;
  division: string;
  labour: number;
  vehicle: number;
  equipment: number;
};