import { useHistory, useLocation } from "react-router-dom";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import React from "react";

export function SidebarItem(props: {
  path: string;
  name: string;
  icon: JSX.Element;
}) {
  const location = useLocation();
  const selected = location.pathname.startsWith(props.path);
  const history = useHistory();

  return (
    <ListItem
      button
      component="a"
      href={props.path}
      onClick={(e: any) => {
        e.preventDefault();
        history.push(props.path);
      }}
      selected={selected}
    >
      <ListItemIcon>{props.icon}</ListItemIcon>
      <ListItemText primary={props.name} />
    </ListItem>
  );
}
