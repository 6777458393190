import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { List, Typography } from "@material-ui/core";
import grey from "@material-ui/core/colors/grey";
import PeopleIcon from "@material-ui/icons/People";
import { SidebarItem } from "./SidebarItem";
import { ProjectSidebarItem } from "./ProjectSidebarItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import { api } from "../api/API";
import {
  useCurrentUser,
  useIsMechanic,
  useIsOfficeManager,
  useIsProjectManager,
} from "../misc/Auth";
import LocalShippingIcon from "@material-ui/icons/LocalShipping";
import BuildIcon from "@material-ui/icons/Build";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import InboxIcon from "@material-ui/icons/Inbox";
import AnnouncementIcon from "@material-ui/icons/Announcement";
import DescriptionIcon from "@material-ui/icons/Description";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import CommuteIcon from "@material-ui/icons/Commute";
import AssessmentIcon from "@material-ui/icons/Assessment";
import { Feedback } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  panel: {
    background: "white",
    height: "100%",
    borderRight: "2px solid " + grey["200"],
    display: "flex",
    flexDirection: "column",
    "& .MuiListItem-gutters": {
      paddingRight: 7,
    },
    "& .MuiListItemIcon-root .MuiSvgIcon-root": {
      height: ".9em",
    },
    "& .MuiListItemIcon-root": {
      minWidth: 40,
      color: grey["700"],
    },
  },
  spacer: {
    height: 10,
  },
  list: {
    flex: 1,
  },
}));

export function Sidebar() {
  const styles = useStyles();
  const isAdmin = useIsOfficeManager();
  const isPM = useIsProjectManager();
  const isMechanic = useIsMechanic();
  const user = useCurrentUser();

  return (
    <div className={styles.panel}>
      <div className={styles.spacer} />
      <div className={styles.list}>
        <List dense style={user ? {} : { display: "none" }}>
          {!isMechanic && (
            <SidebarItem path="/inbox" name="Inbox" icon={<InboxIcon />} />
          )}
          {!isMechanic && <ProjectSidebarItem />}
          {(isAdmin || isPM) && (
            <SidebarItem
              path="/employees"
              name="Employees"
              icon={<PeopleIcon />}
            />
          )}
          {(isAdmin || isPM) && (
            <SidebarItem
              path="/payroll"
              name="Payroll"
              icon={<AttachMoneyIcon />}
            />
          )}
          {!isMechanic && (
            <SidebarItem
              path="/communications"
              name="Communications"
              icon={<AnnouncementIcon />}
            />
          )}
          {!isMechanic && (
            <SidebarItem
              path="/time-sheets"
              name="Time Sheets"
              icon={<AccessTimeIcon />}
            />
          )}
          <SidebarItem
            path="/vehicles"
            name="Vehicles"
            icon={<LocalShippingIcon />}
          />
          <SidebarItem
            path="/equipments"
            name="Equipment"
            icon={<BuildIcon />}
          />
          <SidebarItem
            path="/documents"
            name="Documents"
            icon={<DescriptionIcon />}
          />
          <SidebarItem
            path="/service-requests"
            name="Service Requests"
            icon={<Feedback />}
          />
          <SidebarItem
            path="/service-records"
            name="Service Records"
            icon={<CommuteIcon />}
          />
          {isAdmin && (
            <SidebarItem
              path="/reports"
              name="Reports"
              icon={<AssessmentIcon />}
            />
          )}
        </List>
      </div>
      <ListItem>
        <div>
          <Typography variant="body2" color="textSecondary">
            {user?.firstName} {user?.lastName}
          </Typography>
          <Typography variant="body2" color="textSecondary">
            {user?.workEmail}
          </Typography>
        </div>
      </ListItem>
      <ListItem
        button
        dense
        onClick={async () => {
          api.employees.logout();
        }}
      >
        <ListItemText primary="Logout" />
      </ListItem>
      <div className={styles.spacer} />
    </div>
  );
}
