import { SubAPI } from "./SubAPI";
import { DateString, Paginated } from "./API";
import moment from "moment";
import { PaginationRequestInput } from "../pages/table/TableViewDataSimple";
import {EquipmentTime, EmployeeTime} from "../../../driver-app/terrain-group/core/Types";

export class TimeSheetAPI extends SubAPI {

  create(input: {
    kind: "site",
    foreman: number,
    startTime: Date,
    endTime: Date,
    project: number
    vehicle?: number;
  }) {
    return this.fetcher.post<TimeSheet>(
        "/api/time-sheet/create",
        input
    );
  }

  remove(props: {
    timeSheet: number;
    ids: number[]
  }) {
    return this.fetcher.post<{}>(
        "/api/time-sheet/employee-time/archive",
        props
    );
  }

  changeProject(props: {
    timeSheetId: number;
    detailsId: number;
    projectId: number;
  }) {
    return this.fetcher.post<{}>(
        "/api/time-sheet/change-project",
        props
    );
  }

  getSafetyReportDetails(props: { timeSheet: number }) {
    return this.fetcher.get<SafetyReportDetails>(
      "/api/time-sheet/safety-report-details",
      props
    );
  }

  approve(props: { selection: number[] }) {
    return this.fetcher.post<{}>("/api/time-sheet/approve", props);
  }

  listDriverReports(props: {
    parentId: number;
    pageSize?: number;
    page?: number;
    search?: string;
    orderBy?: string;
    orderByDirection?: string;
  }) {
    return this.fetcher.get<Paginated<DriverReport>>(
      "/api/time-sheet/driver-reports",
      props
    );
  }

  getDriverReport(props: { id: number }) {
    return this.fetcher.get<DriverReport>(
      "/api/time-sheet/driver-report",
      props
    );
  }

  updateDriverReport(props: DriverReport) {
    return this.fetcher.post<DriverReport>(
      "/api/time-sheet/driver-report",
      props
    );
  }

  removeDriverReports(props: {
    ids: number[];
    timeSheet: number;
  }) {
    return this.fetcher.post<{}>("/api/time-sheet/driver-report/archive", props)
  }

  list(
    props:
      | {
          type: "calendar";
          startDate?: DateString;
          endDate?: DateString;
        }
      | {
          type: "project";
          projectId: number;
          pageSize: number;
          page: number;
          search: string;
          orderBy: string;
          orderByDirection: string;
        }
      | ({
          type: "pending-review" | "archived" | "approved";
        } & PaginationRequestInput)
  ) {
    return this.fetcher.get<Paginated<TimeSheet>>(
      "/api/time-sheets",
      props as any
    );
  }

  setArchived(props: { archived: boolean; ids: number[] }) {
    return this.fetcher.post<{}>("/api/time-sheet/set-archived", props);
  }

  getSiteReport(props: { id: number }) {
    return this.fetcher.get<SiteReport>("/api/time-sheet/site-report", props);
  }

  listSiteReports(props: PaginationRequestInput) {
    return this.fetcher.get<Paginated<Omit<SiteReport, "vehicles">>>(
      "/api/time-sheet/site-reports",
      props as any
    );
  }

  removeSites(props: {
    ids: number[];
    timeSheet: number;
  }) {
    return this.fetcher.post<{}>("/api/time-sheet/site-reports/archive", props)
  }

  listEmployeeTime(props: PaginationRequestInput) {
    return this.fetcher.get<Paginated<EmployeeTime>>(
      "/api/time-sheet/employee-times",
      props as any
    );
  }

  setEmployeeTime(props: EmployeeTime) {
    return this.fetcher.post<EmployeeTime>(
      "/api/time-sheet/employee-time",
      props
    );
  }

  listEquipmentTime(props: PaginationRequestInput) {
    return this.fetcher.get<Paginated<EquipmentTime>>(
      "/api/time-sheet/equipment-times",
      props as any
    );
  }

  setEquipmentTime(props: EquipmentTime) {
    return this.fetcher.post<EquipmentTime>(
      "/api/time-sheet/equipment-time",
      props
    );
  }

  updateDetails(props: Partial<{
    detailsId: number;
    timeSheetId: number;
    projectId: number;
    foreman: number;
    vehicles: VehicleDetail[];
    workCompleted: string[];
    subTrades: string[];
    materialsUsed: string[];
    startTime: Date;
    endTime: Date;
  }>) {
    return this.fetcher.post<SiteReport>("/api/time-sheet/site-report", props);
  }

  get(props: { id: number }) {
    return this.fetcher.get<TimeSheet>("/api/time-sheet", props);
  }
}

export interface SafetyReportDetails {
  location: string;
  date: DateString;
  nearestHospitalAddress: string;
  comments: string;
  procedures: string[];
  hazardousIdentification: string;
  signatures: { name: string; signatureImage: string }[];
}

export interface DriverReport {
  id: number;
  driver: number;
  driverName: string;
  deliveryType: string;
  deliveryTypeName: string;
  completed: boolean;
  pickupAtProject?: number;
  pickupAtName: string;
  dropOffAtProject?: number;
  dropOffAtName: string;
  billToProject?: number;
  billToProjectName: string;
  vehicle: number;
  vehicleName: string;
  project?: number;
  projectName: string;
  customer?: number;
  customerName: string;
  startedAt: DateString;
  endedAt: DateString;
  waterUsed: string;
  waterSlip: string;
  description: string;
  timeSheetId: number;
  floating?: FloatItem[];
  labourers?: Labourer[];
}

export type Labourer = {
  id: number;
  personId: number;
  name: string;
};

export type FloatItem = {
  id: number;
  equipmentId?: number;
  name: string;
};

export type VehicleDetail = {
  id: number;
  name: string;
}

export interface SiteReport {
  id: number;
  projectName: string;
  detailsId: number;
  projectId: number;
  timeSheetId: number;
  startTime: DateString;
  endTime: DateString;
  foreman: number;
  foremanName: string;
  vehicles: VehicleDetail[];
  kind: "site" | "snow" | "driver";
  workCompleted: string[];
  subTrades: string[];
  materialsUsed: string[];

  photos: {id: number}[];
  hasSafetyReport: boolean;
}

export interface TimeSheet {
  id: number;
  date: DateString;
  foreman: number;
  foremanName: string;
  division: string;
  sites: {
    id: number; // kind !== driver ? time_sheet_site_details.id : driver_task.id
    description: string;
    timeSheet: number;
    project: number;
    name: string;
    hours: number;
  }[];
  kind: "driver" | "snow" | "site";
  startTime: DateString;
  endTime: DateString;
  hours: number;
  employees: {
    id: number;
    name: string;
  }[];
  inProgress: boolean;
  approved: boolean;
}

export type TimeSheetWithDates = TimeSheet & {
  date: moment.Moment;
  startTime: moment.Moment;
  endTime: moment.Moment;
};
