import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import React, { useCallback } from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import grey from "@material-ui/core/colors/grey";
import { EditableCol } from "../TableViewData";
import moment from "moment";
import { dateFormat } from "./DateCell";
import clsx from "clsx";
import { Cents, formatCents } from "nate-react-api-helpers";
import { dateTimeFormat } from "./DateTimeCell";
import { ensurePrecision } from "./MoneyFloatCell";
import { timeFormat } from "./TimeCell";

const useStyles = makeStyles((theme) => ({
  placeholderText: {
    color: grey["600"],
    fontSize: "0.85rem",
    flex: 1,
  },
  valueText: {
    fontSize: "0.85rem",
    flex: 1,
  },
  leftAlign: {
    textAlign: "left",
  },
  rightAlign: {
    textAlign: "right",
  },
  tableCellDisabled: {
    background: grey["200"],
  },
  tableCellDefault: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    height: "100%",
    border: "2px solid transparent",
    paddingLeft: "13px !important",
    paddingRight: "5px !important",
  },
  tableCellEnabled: {
    borderRadius: 4,
    "&:hover": {
      border: "2px solid " + theme.palette.primary.main,
    },
  },
  dropArrow: {
    borderRadius: "50%",
    cursor: "pointer",

    "&:hover": {
      background: grey["200"],
    },
  },
}));

export function ValueCell<T extends { id: number }>(props: {
  column: EditableCol<T>;
  row: T;
  value?: any;
  locked?: boolean;
  onClick?(): void;
}) {
  const styles = useStyles();
  const _onClick = props.onClick;

  const onClick = useCallback(
    (e) => {
      if (!_onClick) return;

      let el = e.currentTarget;
      while (el.tagName !== "TD") el = el.parentElement;

      el.style.width = window.getComputedStyle(el).width;
      _onClick();
    },
    [_onClick]
  );

  return (
    <div
      className={clsx({
        [styles.tableCellDefault]: true,
        [styles.tableCellDisabled]:
          props.column.type === "computed" || props.locked,
        "computed-cell-value": props.column.type === "computed" || props.locked,
        [styles.tableCellEnabled]:
          props.column.type !== "computed" && !props.locked,
      })}
      onClick={onClick}
    >
      {props.value || props.value === 0 ? (
        <RealValue row={props.row} value={props.value} column={props.column} />
      ) : (
        <PlaceholderValue column={props.column} />
      )}
      {(props.column.type === "date" ||
        props.column.type === "date-time" ||
        props.column.type === "time" ||
        props.column.type === "select-from-list" ||
        props.column.type === "select-entity-from-list") && (
        <ArrowDropDownIcon className={styles.dropArrow} />
      )}
    </div>
  );
}

export function valueToString<T extends { id: number }>(
  row: T,
  value: any,
  column: EditableCol<T>
) {
  switch (column.type) {
    case "date":
      return moment(value as Date).format(column.displayFormat || dateFormat);
    case "date-time":
      return moment(value as Date).format(
        column.displayFormat || dateTimeFormat
      );
    case "time":
      return moment(value as Date).format(column.displayFormat || timeFormat);
    case "money-float":
      return ensurePrecision(value / 100, 2);
    case "money":
      return formatCents(value as Cents);
    case "number":
      return value.toString();
    case "select-from-list":
      if (column.displayKey && row[column.displayKey]) {
        return row[column.displayKey];
      }

      return value.toString();
    case "select-entity-from-list":
      return row[column.displayKey];
    case "float":
      return value.toFixed(3);
    default:
      return value.toString();
  }
}

function RealValue<T extends { id: number }>(props: {
  row: T;
  value: any;
  column: EditableCol<T>;
}) {
  const styles = useStyles();

  let alignment: "left" | "right";

  if (props.column.align !== undefined) {
    alignment = props.column.align;
  } else {
    switch (props.column.type) {
      case "date-time":
      case "time":
      case "float":
      case "money":
      case "money-float":
      case "number":
        alignment = "right";
        break;
      case "computed":
      case "date":
      case "select-entity-from-list":
      case "select-from-list":
      default:
        alignment = "left";
        break;
    }
  }

  return (
    <span
      className={clsx({
        [styles.valueText]: true,
        [styles.leftAlign]: alignment === "left",
        [styles.rightAlign]: alignment === "right",
      })}
    >
      {valueToString(props.row, props.value, props.column)}
    </span>
  );
}

function PlaceholderValue<T extends { id: number }>(props: {
  column: EditableCol<T>;
}) {
  const styles = useStyles();

  let alignRight = props.column.align === "right";

  switch (props.column.type) {
    case "number":
    case "money-float":
    case "money":
    case "float":
      alignRight = true;
      break;
  }

  return (
    <span
      className={clsx({
        [styles.placeholderText]: true,
        [styles.rightAlign]: alignRight,
      })}
    >
      {props.column.placeholder || (
        <span dangerouslySetInnerHTML={{ __html: "&nbsp;" }} />
      )}
    </span>
  );
}
