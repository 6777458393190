import React, { useMemo, useState } from "react";
import { Col, OrderByDirection } from "./TableViewData";
import { TableViewTabs } from "./TableViewTabs";
import { TableViewSearch } from "./TableViewSearch";
import { TableView } from "./TableView";
import { Paginated } from "../../api/API";
import { TableViewDataAsync } from "./TableViewDataAsync";

export const pageSize = 50;

export function TableViewSimple<T extends { id: number }>(props: {
  tabs: string[];
  cols: Col<T>[];
  searchPlaceholder: string;
  fetcher: (opts: {
    pageSize: number;
    page: number;
    tab: string;
    search: string;
    orderBy: string;
    orderByDirection: OrderByDirection;
  }) => Promise<Paginated<T>>;
  navigateTo(row: T): string;
  moreOptions: JSX.Element;
  onSelectionChange(values: number[]): void;
}) {
  const [search, setSearch] = useState("");
  const [tab, setTab] = useState(props.tabs[0]);
  const tabValues = useMemo(
    () => props.tabs.map((t) => ({ name: t, disabled: false })),
    [props.tabs]
  );

  return (
    <TableView
      tabs={<TableViewTabs onChange={setTab} values={tabValues} />}
      search={
        <TableViewSearch
          placeholder={props.searchPlaceholder}
          onChange={setSearch}
        />
      }
      moreOptions={props.moreOptions}
      table={
        <TableViewDataAsync
          tab={tab}
          search={search}
          cols={props.cols}
          fetcher={props.fetcher}
          navigateTo={props.navigateTo}
          onSelectionChange={props.onSelectionChange}
        />
      }
    />
  );
}

export const blankArray: any[] = [];
