import { SubAPI } from "./SubAPI";

export class CustomerAPI extends SubAPI {
  autoComplete(props: { search: string }) {
    return this.fetcher.get<Customer[]>("/api/customers/autocomplete", props);
  }
}

export interface Customer {
  id: number;
  name: string;
}

export interface AutoCompleteItem {
  id: number;
  name: string;
}
