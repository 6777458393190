import { SubAPI } from "./SubAPI";
import { DateString, Paginated } from "./API";
import { OrderByDirection } from "../pages/table/TableViewData";

export class InboxAPI extends SubAPI {
  list(props: Partial<{
    archived: boolean;
    page: number;
    search: string;
    pageSize: number;
    orderBy: string;
    orderByDirection: OrderByDirection;
  }>) {
    return this.fetcher.get<Paginated<InboxItem>>("/api/inbox", props);
  }

  setArchived(props: {ids: number[]; archived: boolean}) {
    return this.fetcher.post<void>("/api/inbox/set-archived", props);
  }
}

type PmTimeSheetReview = {
  kind: "pm-time-sheet-review";
  args: {
    kind: "site" | "snow" | "driver";
    siteName: string;
    timeSheet: number;
    driverTask?: number;
    timeSheetSiteDetails?: number;
    foreman: number;
    foremanName: string;
  }
}
type PayrollArg = {
  payroll: number;
  periodStart: DateString;
  periodEnd: DateString;
}
type OmPayrollReview = {
  kind: "om-payroll-review";
  args: PayrollArg;
};
type PmPayrollReview = {
  kind: "pm-payroll-review";
  args: PayrollArg;
};


export type InboxItem = {
  id: number;
  person: number;
  createdAt: DateString;
  archived: boolean;
} & (PmTimeSheetReview | OmPayrollReview | PmPayrollReview)