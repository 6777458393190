import { Cents, formatCents, useAsync2 } from "nate-react-api-helpers";
import { api } from "../../api/API";
import React from "react";
import Divider from "@material-ui/core/Divider";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";

export function AccountingSummary(props: { id: number }) {
  const result = useAsync2(
    (input) => api.projects.getAccountingSummary(input),
    {
      id: props.id,
    },
    [props.id]
  );
  const summary = result.result;

  return (
    <>
      <div style={{ fontSize: 20, padding: 8, paddingLeft: 18 }}>
        Accounting Summary
      </div>
      <Divider />
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Total Revenue</TableCell>
            <TableCell>Total Cost</TableCell>
            <TableCell>Profit (loss)</TableCell>
            <TableCell>Profit Margin</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {!result.loadingOrError && (
            <TableRow>
              <TableCell>{formatCents(summary?.totalRevenueCents)}</TableCell>
              <TableCell>{formatCents(summary?.totalCostCents)}</TableCell>
              <TableCell>
                {formatCentsAccountingStyle(summary?.profitCents)}
              </TableCell>
              <TableCell>{summary?.profitMargin}%</TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      {result.LoadingOrErrorElement}
    </>
  );
}

export function formatCentsAccountingStyle(value: Cents | undefined) {
  if (value === undefined) return "";

  const brackets = value < 0;
  const str = formatCents(value);
  if (brackets) return "(" + str + ")";
  return str;
}
