import { SubAPI } from "./SubAPI";
import { DateString, Paginated } from "./API";
import { OrderByDirection } from "../pages/table/TableViewData";
import { Cents } from "nate-react-api-helpers";

export class EmployeeAPI extends SubAPI {
  list(props: {
    page: number;
    search: string;
    active: boolean;
    pageSize: number;
    orderBy: string;
    orderByDirection: OrderByDirection;
  }) {
    return this.fetcher.get<Paginated<Employee>>("/api/employees", props);
  }

  listForAutoComplete(props: {
    kind?: "project-manager" | "field" | "foreman" | "mechanic" | "driver";
  }) {
    return this.fetcher.get<EmployeeForAutoComplete[]>(
      "/api/employees/auto-complete",
      props
    );
  }

  logout() {
    // @ts-ignore
    window.location = "/api/auth/logout";
  }

  login(input: { username: string; password: string }) {
    return this.fetcher.postForAuth("/api/auth/login", input);
  }

  get(id: number) {
    return this.fetcher.get<Employee>("/api/employee", { id: id });
  }

  create(employee: Omit<Employee, "id">) {
    return this.fetcher.post<Employee>("/api/employee/create", employee);
  }

  update(employee: Employee) {
    return this.fetcher.post<Employee>("/api/employee/update", employee);
  }

  setPassword(input: { id: number; password: string }) {
    return this.fetcher.post<Employee>("/api/employee/set-password", input);
  }

  initiateResetPassword(input: { email: string }) {
    return this.fetcher.post<{}>("/api/initiate-reset-password", input);
  }

  completeResetPassword(input: { code: string; password: string }) {
    return this.fetcher.post<{}>("/api/complete-reset-password", input);
  }

  removeWages(ids: number[]) {
    return this.fetcher.post<{}>("/api/employee-wage/remove", {
      ids: ids,
    });
  }

  listWages(input: { employee: number }) {
    return this.fetcher.get<Paginated<Wage>>("/api/employee-wages", input);
  }

  createWage(input: Wage) {
    return this.fetcher.post<Wage>("/api/employee-wage", input);
  }

  updateWage(input: Wage) {
    return this.fetcher.post<Wage>("/api/employee-wage/update", input);
  }
}

export interface EmployeeForAutoComplete {
  id: number;
  firstName: string;
  lastName: string;
}

export interface Employee {
  id: number;
  firstName: string;
  lastName: string;
  division: string;
  role: string;
  homePhone: string;
  homeEmail: string;
  emergencyContact: string;
  workEmail: string;
  workPhone: string;
  lastDate?: DateString;
  leavingReason: string;
  isSalary: boolean;
  archived: boolean;
  selectableInMobileApp: boolean;
}

export interface Wage {
  id: number;
  person: number;
  startDate: DateString;
  hourlyRateCents: Cents;
}
