import React, { useCallback } from "react";
import { TextCell } from "./TextCell";
import { formatCents, parseCents } from "nate-react-api-helpers";
import { CellChange } from "./EditCell";

export function MoneyCell(props: {
  value: any;
  onCancel(): void;
  onChange: CellChange;
}) {
  const _onChange = props.onChange;

  const onChange = useCallback(
    (value: string, extra?: any) => {
      try {
        _onChange(parseCents(value), extra);
      } catch (e) {
        _onChange(0, extra);
      }
    },
    [_onChange]
  );

  return (
    <TextCell
      value={formatCents(props.value)}
      align="right"
      onCancel={props.onCancel}
      onChange={onChange}
    />
  );
}
