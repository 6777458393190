import { SubAPI } from "./SubAPI";
import { API, DateString } from "./API";
import { Cents } from "nate-react-api-helpers";
import { OrderByDirection } from "../pages/table/TableViewData";

export class CostingAPI extends SubAPI {
  key: string;

  constructor(key: string, api: API) {
    super(api);
    this.key = key;
  }

  list(props: {
    parentId?: number;
    page: number;
    download?: string;
    search: string;
    pageSize: number;
    orderBy: string;
    filter: string;
    orderByDirection: OrderByDirection;
  }) {
    return this.fetchPaginated<MaterialCost>(
      `/api/costing/${this.key}/list`,
      props
    );
  }

  remove(props: number[]) {
    return this.fetcher.post<{}>(`/api/costing/${this.key}/remove`, props);
  }

  insert(props: any) {
    return this.fetcher.post<MaterialCost>(
      `/api/costing/${this.key}/create`,
      props
    );
  }

  update(props: MaterialCost) {
    return this.fetcher.post<MaterialCost>(
      `/api/costing/${this.key}/update`,
      props
    );
  }

  utm() {
    return this.fetcher.get<string[]>(`/api/costing/${this.key}/utm-list`);
  }

  vendors() {
    return this.fetcher.get<string[]>(`/api/costing/${this.key}/vendor-list`);
  }
}

export interface MaterialCost {
  id: number;
  date: DateString;
  source: number;
  sourceName: string;
  invoiceNumber: string;
  ticketNumber: string;
  description: string;
  qty: number;
  utm: string;
  unitCostCents: Cents;
}

export type RentalCost = MaterialCost;
export type ContractorCost = MaterialCost;
