import {
  Col,
  EditableCol,
  OrderByDirection,
  TableViewData,
} from "./TableViewData";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useAsync2 } from "nate-react-api-helpers";
import { blankArray, pageSize } from "./TableViewSimple";
import { Paginated } from "../../api/API";
import { TableContext } from "./TableProvider";
import { encodeFilter, Filter } from "./TableFilter";
import { SubColumn } from "./sectioned/SectionedTable";
import { valueToString } from "./editable/ValueCell";

export interface PaginationRequestInput {
  pageSize: number;
  page: number;
  search: string;
  filter: string;
  orderBy: string;
  orderByDirection: OrderByDirection;
  download?: string;
  parentId?: number;
}

interface DefaultProps<T extends { id: number }> {
  fetcher: (opts: PaginationRequestInput) => Promise<Paginated<T>>;
  filter: Filter[];
  search: string;
  parentId?: number;
  noPaginate?: boolean;
  orderByColumnName?: string;
  lockOrderBy?: boolean;
  onSelectionChange(values: number[]): void;
  navigateTo?(row: T): string;
}

type Props<T extends { id: number }> = DefaultProps<T> &
  (
    | {
        cols: EditableCol<T>[];
        editable: true;
        rowIsLocked?: (row: T) => boolean;
        insert?: (input: T) => Promise<T>;
        update?(value: T): Promise<T>;
      }
    | {
        cols: Col<T>[];
        editable: false;
      }
  );

function columnsAreEditable<T extends { id: number }>(
  cols: Col<T>[] | SubColumn<T>[] | EditableCol<T>[],
  editable: boolean
): cols is EditableCol<T>[] {
  return editable;
}

function isSubCols<T extends { id: number }>(
  cols: Col<T>[] | SubColumn<T>[]
): cols is SubColumn<T>[] {
  if (cols.length === 0) return false;
  return (
    (cols as (Col<T> | SubColumn<T>)[]).filter((c) => "key" in c).length > 0
  );
}

function convertOrderByToColumnName<T extends { id: number }>(
  name: string,
  columns: EditableCol<T>[]
) {
  const matches = columns.filter((c) => c.name === name);
  if (matches.length === 1) {
    const key = matches[0].key;
    if (key !== undefined) return key.toString();
  }

  return name;
}

export function TableViewDataSimple<T extends { id: number }>(props: Props<T>) {
  const parentId = props.parentId;
  const search = props.search;
  const filter = props.filter;
  const [page, setPage] = useState(0);
  const [orderBy, setOrderBy] = useState<string>(
    props.orderByColumnName || props.cols[0].name
  );
  const [orderByDirection, setOrderByDirection] = useState<OrderByDirection>(
    "asc"
  );

  const orderByChange = useCallback(
    (col: string, direction: OrderByDirection) => {
      setOrderBy(col);
      setOrderByDirection(direction);
    },
    []
  );

  const [inserted, setInserted] = useState<T[]>([]);

  const list = useAsync2(
    async (opts) => {
      const result = await props.fetcher(opts);
      setInserted([]);
      return result;
    },
    {
      page: page,
      search: search,
      orderBy: props.editable
        ? convertOrderByToColumnName(orderBy, props.cols)
        : orderBy,
      filter: encodeFilter(filter),
      orderByDirection: orderByDirection,
      parentId,
      pageSize: props.noPaginate ? 1e6 : pageSize,
    },
    [
      page,
      filter,
      search,
      orderBy,
      orderByDirection,
      parentId,
      props.noPaginate,
    ]
  );

  // @ts-ignore
  const _insert = props.insert;
  let insert: any = useCallback(
    async (input: any) => {
      if (!_insert) return;
      const result = await _insert(input);
      setInserted((old) => [result, ...old]);
      return result;
    },
    [_insert]
  );
  if (!_insert) {
    insert = undefined;
  }

  // @ts-ignore
  const updater = props.update;

  let rows = list.result?.data || blankArray;
  rows = useMemo(() => [...inserted, ...rows], [inserted, rows]);

  const ctx = useContext(TableContext);
  const register = ctx.registerTable;
  const listReloader = list.reload;
  const fetcher = props.fetcher;
  const columns = props.cols;
  const editable = props.editable;

  useEffect(() => {
    register({
      reload() {
        listReloader();
        setInserted([]);
      },
      async download(filename: string) {
        let list: T[] = [];
        let expected = 0;

        do {
          const result = await fetcher({
            search: "",
            orderBy: orderBy,
            orderByDirection: orderByDirection,
            parentId,
            filter: encodeFilter(filter),
            page: 0,
            pageSize: 0,
          });

          list.push(...result.data);
          expected = result.totalCount;
        } while (list.length < expected);

        downloadResultAsFile(filename, list, columns, editable);
      },
    });
  }, [
    register,
    filter,
    listReloader,
    fetcher,
    orderBy,
    orderByDirection,
    parentId,
    columns,
    editable,
    rows,
  ]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const navigateTo = useMemo(() => props.navigateTo, []);

  return (
    <TableViewData
      totalCount={list.result?.totalCount || 0}
      columns={props.cols as any}
      navigateTo={navigateTo}
      rowIsLocked={props.editable ? props.rowIsLocked : undefined}
      rows={rows}
      insert={insert}
      update={updater}
      loading={list.loading}
      error={list.error}
      onOrderByChange={props.lockOrderBy ? () => {} : orderByChange}
      orderBy={orderBy}
      editable={props.editable}
      orderByDirection={orderByDirection}
      onSelectionChanged={props.onSelectionChange}
      onPageChange={setPage}
      rowsPerPage={pageSize}
      page={page}
      noPaginate={props.noPaginate}
    />
  );
}

function extractTextFromReact(obj: any): string {
  if (obj === undefined || obj === null) return "";
  if (typeof obj === "string") return obj;

  if (typeof obj === "object" && "props" in obj) {
    if (obj.props.children instanceof Array) {
      return obj.props.children.map(extractTextFromReact).join("");
    }

    return extractTextFromReact(obj.props.children);
  }

  return obj.toString();
}

function toCSV(data: string[][]) {
  const rows = data.map((row) => {
    return row
      .map((value) => {
        if (/[,\r\n]/.exec(value) !== null) {
          return '"' + value.replace(/"/g, '""') + '"';
        }

        return value;
      })
      .join(",");
  });

  return rows.join("\r\n");
}

export function downloadResultAsFile<T extends { id: number }>(
  filename: string,
  list: T[],
  columns: SubColumn<T>[] | Col<T>[] | EditableCol<T>[],
  editable: boolean
) {
  let renderer: { name: string; lookup: (row: T) => any }[] = [];
  if (columnsAreEditable(columns, editable)) {
    renderer = columns
      .filter((c) => c.actions !== true)
      .map((c) => {
        if (c.key !== undefined) {
          const key = c.key;
          return {
            name: c.name,
            lookup: (row: T) => valueToString(row, row[key], c),
          };
        }

        switch (c.type) {
          case "computed":
            return {
              name: c.name,
              lookup: (row: T) => extractTextFromReact(c.selector(row)),
            };
          case "select-entity-from-list":
            return {
              name: c.name,
              lookup: (row: T) => row[c.displayKey],
            };
          default:
            return { name: c.name, lookup: (row: T) => "" };
        }
      });
  } else if (isSubCols(columns)) {
    renderer = columns
      .filter((c) => c.actions !== true)
      .map((c) => ({
        name: c.name,
        lookup: (row: T) =>
          extractTextFromReact("selector" in c ? c.selector(row) : row[c.key]),
      }));
  } else {
    renderer = columns
      .filter((c) => c.actions !== true)
      .map((c) => ({
        name: c.name,
        lookup: (row: T) => extractTextFromReact(c.selector(row)),
      }));
  }

  downloadCSV(filename, [
    renderer.map((col) => col.name),
    ...list.map((row) => {
      return renderer.map((col) => col.lookup(row));
    }),
  ]);
}

export function downloadCSV(filename: string, input: string[][]) {
  const csv = toCSV(input);

  const blob = new Blob([csv], { type: "text/csv" });
  let fileURL = window.URL.createObjectURL(blob);

  let link = document.createElement("a");
  link.href = fileURL;
  link.download = filename;
  link.click();

  setTimeout(() => {
    window.URL.revokeObjectURL(fileURL);
  }, 250); // firefox requires timeout
}
