import { SubAPI } from "./SubAPI";
import { DateString } from "./API";
import {Diff} from "../pages/audit/AuditDetails";

export class AuditAPI extends SubAPI {
  list(props: { type: AuditType; id: number }) {
    return this.fetcher.get<Audit[]>("/api/audit/list", props);
  }

  summary(props: { type: AuditType; id: number }) {
    return this.fetcher.get<AuditSummary[]>("/api/audit/summary", props);
  }
}

export type AuditType =
  | "person"
  | "vehicle"
  | "equipment"
  | "project"
  | "material-cost"
  | "rental-cost"
  | "contractor-cost"
  | "labour-cost"
  | "project-invoice"
  | "employee-time"
  | "equipment-time"
  | "site-details"
  | "driver-task"
  | "time-sheet"
  | "employee-wage"
  | "equipment-rate"
  | "equipment-cost"
  | "vehicle-cost"
    | "document"
    | "payroll-person"
    | "service-record"
    | "service-record-cost"
| "service-request";

export interface Audit {
  id: number;
  timestamp: DateString;
  author: number;
  authorName: string;
  action: string;
  change: { [k: string]: Diff };
  original: { [k: string]: any };
}

export type AuditSummary = Audit;
